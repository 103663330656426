import { useCallback, useState } from "react";
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isDate from 'validator/lib/isDate';
import isBefore from 'validator/lib/isBefore';
import useTranslation from "./useTranslation";

type HookReturn = {
  errors: any,
  validate: any,
  updateConfig: any,
  validateFromAPI: any,
  showError: (field: string, value: string) => void,
  clearError: (field: string) => void,
}

const mountConfigObject = (config: any) => {
  let validationObject: any = {};
  
  Object.keys(config).map( key => validationObject[key] = '');

  return validationObject;
}

const readValidationType = (validation: string): any => {
  const validationParts = validation.split(':');

  if ( validationParts.length === 1 ) {
    return {
      type: validationParts[0],
      value: null
    };
  } else {
    return {
      type: validationParts[0],
      value: validationParts[1]
    };
  }
}

const useValidator = (setupConfig: any, customMessages: any = null): HookReturn => {

  const [errors, setErrors] = useState<any>(mountConfigObject(setupConfig));
  const [config, setConfig] = useState(setupConfig);
  const i = useTranslation();

  /**
   * 
   * @param errorsStack 
   * @returns 
   */
  const _hasErrors = (errorsStack: any) => {
    let errorCount = 0;

    for ( let errorField in errorsStack ) {
      if ( typeof errorsStack[errorField] === 'string' && errorsStack[errorField] !== '' ) {
        errorCount++;
      }
    }

    return errorCount > 0;
  }

  /**
   * remove nested object key used by laravel
   * @param key 
   */
  const _removeNested = (key: string) => {
    const lastKey = key.split('.').pop();

    return lastKey || '';
  }

  /**
   * Apply errors coming from API response
   * @param error 
   * @returns 
   */
  const validateFromAPI = (error: any) => {
    if ( error && error.data && error.data.fields ) {
      let errorsStack: any[] = [];
      
      for ( let keyI in error.data.fields ) {
        const key = _removeNested(keyI);
        errorsStack.push({ [key]: error.data.fields[keyI][0] })
      }
      
      if ( errorsStack.length > 0 ) {
        const errorStackObject = errorsStack.length > 0 
            ? errorsStack.reduce((result, o) => Object.assign(result, o))
            : {};

        setErrors(errorStackObject);
        return true;
      }
    }

    return false;
  }

  /**
   * Update validation settings dynamically
   */
  const updateConfig = useCallback((newConfig: any) => {
    setErrors(mountConfigObject(newConfig));
    setConfig(newConfig);
  }, []);

  /**
   * 
   * @param formValues 
   * @returns 
   */
  const validate = (formValues: any, keepFields: string[]|null) => new Promise((resolve, reject) => {
    
    /**
     * Reset form State
     */
    if ( formValues === false ) {
      setErrors(mountConfigObject(config));
    }
    
    let errorsStack: any[] = []; // Object.keys(errors).map( key => ({ [key]: errors[key] })
    
    for ( let field in formValues ) {

      if ( keepFields && keepFields.indexOf(field) !== -1 ) {
        errorsStack.push({ [field]: errors[field] })
        continue;
      }

      const validations = config[field];
      const value = formValues[field];
      
      for ( let indexValidation in validations ) {
        const validation = readValidationType(validations[indexValidation]);

        if ( validation.type === 'required' ) {
          if ( typeof value === 'string' && isEmpty(value)) {
            errorsStack.push({ [field]: i._('validation.required') });
          }

          if ( !value ) {
            errorsStack.push({ [field]: i._('validation.required') });
          }
        }
  
        if ( validation.type === 'isEmail' && !isEmail(value) ) {
          errorsStack.push({ [field]: i._('validation.email') });
        }

        if ( validation.type === 'pastUSDate' ) {
          if ( !isDate(value, { format: 'MM/DD/YYYY'}) ) {
            errorsStack.push({ [field]: i._('validation.date') });
          } else {
            if ( !isBefore(value) ) {
              errorsStack.push({ [field]: i._('validation.past_date') });  
            }
          }
        }

        if ( validation.type === 'max' && typeof value === 'string' ) {
          if ( value.length > validation.value ) {
            errorsStack.push({ [field]: i._('validation.max_length', { v: validation.value }) })
          }
        }

        if ( validation.type === 'min' && typeof value === 'string' ) {
          if ( value.length < validation.value ) {
            errorsStack.push({ [field]: i._('validation.min_length', { v: validation.value }) });
          }
        }

        if ( ['gt', 'lt', 'gte'].indexOf(validation.type) !== -1 ) {

          const validationValue = parseFloat(validation.value);
          
          if ( validation.type === 'gt' && parseFloat(value) <= validationValue ) {
            let message = i._('validation.above', { v: validationValue });

            if ( customMessages?.gt ) {
              message = customMessages.gt;
            }

            errorsStack.push({ [field]: message });
          }

          if ( validation.type === 'gte' && parseFloat(value) < validationValue ) {
            let message = i._('validation.aboveequal', { v: validationValue });

            if ( customMessages?.gt ) {
              message = customMessages.gt;
            }

            errorsStack.push({ [field]: message });
          }
          
          if ( validation.type === 'lte' && parseFloat(value) > validationValue ) {
            let message = i._('validation.lower', { v: validationValue });

            if ( customMessages?.lt ) {
              message = customMessages.lt
            }

            errorsStack.push({ [field]: message });
          }

          if ( validation.type === 'lt' && value >= validationValue ) {
            let message = i._('validation.lower', { v: validationValue });

            if ( customMessages?.lt ) {
              message = customMessages.lt
            }

            errorsStack.push({ [field]: message });
          }
        }

        if ( typeof value === 'number' && validation.type === 'gt0' && value <= 0 ) {
          errorsStack.push({ [field]: 'Informe um valor maior que zero' });
        }

        if ( validation.type === 'birthdate' ) {

          if(value !== '' && value !== null && value !== undefined){

            if ( !isDate(value, { format: 'YYYY/MM/DD'}) ) {
              errorsStack.push({ [field]: i._('validation.date') });
            } else {
  
              var split_date = (new Date()).toISOString().split('T')[0].split('-');
              var date = (parseInt(split_date[0]) - 10) + '-' + (parseInt(split_date[1]) - 1) + '-' + split_date[2];
  
              if ( !isBefore(value, date) ) {
                errorsStack.push({ [field]: i._('validation.birthdate_invalid') });  
              }
            }
          }
        }
      }
    }

    
    const errorStackObject = errorsStack.length > 0 
      ? errorsStack.reduce((result, o) => Object.assign(result, o))
      : {};
    
    if ( _hasErrors(errorStackObject) ) {
      setErrors(errorStackObject);
      reject(errorStackObject);
    } else {
      setErrors(mountConfigObject(config))
      resolve(true);
    }
  });

  const showError = (field: string, value: string) => {
    const newErrors = { ...errors, [field]: value };
    setErrors(newErrors);
  }

  const clearError = (field: string) => {
    const newErrors = { ...errors, [field]: '' };
    setErrors(newErrors);
  }

  return {
    errors,
    validate,
    showError,
    clearError,
    updateConfig,
    validateFromAPI
  }
}

export default useValidator;