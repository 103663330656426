import React from "react";
import ActionSheet from "common/components/ui/ActionSheet";
import { useDispatch } from "react-redux";
import { ClientTypes } from "reducers/clientReducer";
import CashoutForm from "./CashoutForm";
import useTranslation from "hooks/useTranslation";

const CashoutFormModal = () => {
  const dispatch = useDispatch();
  const i = useTranslation();
  const onClose = () => {
    dispatch({
      type: ClientTypes.TOGGLE_CASHOUT_FORM
    });
  }

  return (
    <ActionSheet title={i._('cashout.new')} onClose={onClose}>
      <div className="mx-3 my-3 mt-2">
        <CashoutForm />
      </div>
    </ActionSheet>
  );
}

export default CashoutFormModal;