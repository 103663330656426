import { Button, TextInput } from "common/components/ui/Form";
import { Col, Row } from "common/components/ui/Layout";
import { useTranslation } from "hooks";
import React, { FormEvent, useState } from "react";
import LatamQuoteEstimate from "./LatamQuoteEstimate";
import { useDispatch } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import LatamRequests from "api/LatamRequests";
import { useAuth } from "reducers/userReducer";
import { extractData } from "api";

export type ValuesType = {
    price: number
}

type LatamFormProps = {
    onSubmit: (pixDetails: any) => void
}

const LatamForm: React.FC<LatamFormProps> = ({ onSubmit }) => {
    const i = useTranslation();
    const dispatch = useDispatch();
    const authUser = useAuth();

    const [loading, setLoading] = useState(false);
    const [prices, setPrices] = useState<ValuesType>({
        price: 0
    })

    const [form, setForm] = useState({
        value: ''
    })

    const onInputChange = (e: FormEvent<HTMLInputElement>): void => {
        setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value })
    }

    const _onClick = () => {
        setLoading(true);

        const data = {
            price: prices.price,
            // eslint-disable-next-line
            amount: parseFloat(form.value.replace(/\,/g, '')),
            client_id: authUser?.id
        }
        
        LatamRequests.create(data)
            .then(extractData)
            .then(response => {
                dispatch({ type: NavigationTypes.TOGGLE_FOCUS_MODE, payload: true });
                window.onbeforeunload = () => true;
                onSubmit(response);
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
    }

    return (
        <div>
            <Row className="mt-2">
                <Col md={12}>
                    <TextInput
                        type="text" name="value"
                        mask="currency:USD" placeholder={i._('deposit.ph_deposit')}
                        label={i._('deposit.value_in', { c: 'U$' })}
                        value={form.value} onChange={onInputChange} />
                </Col>
            </Row>
            { form.value !== '' && <LatamQuoteEstimate onCalculate={(v) => setPrices(v)} value={form.value} /> }
            <Row className="mt-3">
                <Col md={12}>
                    <Button type="button" onClick={_onClick} title={i._('deposit.generate_qrcode')} color="primary" loading={loading} className="save-button" size="lg" block />
                </Col>
            </Row>
        </div>
    )
}

export default LatamForm;