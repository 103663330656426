import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import { ApplicationState } from "store";
import ReactDOM from "react-dom";

type ToastProps = {
  closeButton?: boolean
}

const TOAST_TIME = 2500;

const Toast: React.FC<ToastProps> = ({ closeButton = false }) => {

  const toast = useSelector((state: ApplicationState) => state.navigation.toast);
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if ( toast ) {
      setTimeout(() => setShow(true), 150);
      setTimeout(() => dispatch({ type: NavigationTypes.SHOW_TOAST, payload: false }), TOAST_TIME);
    } else {
      setShow(false);
    }
  }, [toast, dispatch]);

  if ( !toast && false ) {
    return null;
  }

  return ReactDOM.createPortal(
    <div id="toast-7" className={"toast-box toast-bottom " + ( show ? 'show' : '')}>
      <div className="in">
        <div className="text">
          { toast }
        </div>
      </div>
      {
        closeButton
          ? <button type="button" className="btn btn-sm btn-text-light close-button">OK</button>
          : null
      }
    </div>
  , document.body);
}

export default Toast;