import { RadioInput } from "common/components/ui/Form";
import { Col } from "common/components/ui/Layout";
import { useTranslation } from "hooks";
import React, { FormEvent } from "react";
import { useCurrentFund } from "reducers/navigationReducer";
import { useAuth } from "reducers/userReducer";

type Props = {
    onSelected: (value: FormEvent<HTMLInputElement>) => void;
    value: string,
    masterAccess: boolean
}

const DepositCurrencyOptions: React.FC<Props> = ({ onSelected, value, masterAccess }) => {

    const i = useTranslation();
    const authUser = useAuth();
    const currentFund = useCurrentFund();

    return (
        <>
            {
              authUser?.aliquot_brl && process.env.REACT_APP_OFFICE_ID !== '2'
                ? <Col sm={12} lg={12} xxl={4}>
                    <RadioInput name="type" value="BRL" id="type-1" label={i._('currency.real')}
                      selected={value === 'BRL'}
                      description="R$"
                      border
                      onChange={onSelected} />
                  </Col>
                : null
            }
            {
              authUser?.aliquot_usd && (currentFund.id !== 1 || masterAccess)
                ? <Col sm={12} lg={12} xxl={4}>
                    <RadioInput name="type" value="USD" id="type-2" label={i._('currency.dollar')}
                      selected={value === 'USD'}
                      description="U$"
                      border
                      onChange={onSelected} />
                  </Col>
                : null
            }
            {
              authUser?.aliquot_btc && authUser?.useramount[0] && authUser?.useramount[0].btc > 0 && currentFund.id !== 1
                ? <Col sm={12} lg={12} xxl={4}>
                    <RadioInput name="type" value="BTC" id="type-3" label={i._('currency.bitcoin')}
                      selected={value === 'BTC'}
                      description="BTC"
                      border
                      onChange={onSelected} />
                  </Col>
                : null
            }
            {
              authUser?.aliquot_usdt
                ? <Col sm={12} lg={12} xxl={4}>
                    <RadioInput name="type" value="USDT" id="type-4" label={i._('currency.tether')}
                      selected={value === 'USDT'}
                      description="USDT"
                      border
                      onChange={onSelected} />
                  </Col>
                : null
            }
            {
              process.env.REACT_APP_OFFICE_ID === '2' && false
                ? <Col sm={12} lg={12} xxl={4}>
                    <RadioInput name="type" value="USDPIX" id="type-5" label={'Dollar via PIX BRL'}
                      selected={value === 'USDPIX'}
                      description="U$"
                      border
                      onChange={onSelected} />
                  </Col>
                : null
            }
        </>
    )
}

export default DepositCurrencyOptions;