import CopyContent from "common/components/CopyContent";
import React from "react";
import { FormattedMessage } from "react-intl";
import ForeignBankItem from "./ForeignBankItem";
import WalletBankItem from "./WalletBankItem";

const BankItem = ({ ...bank }) => {

  if ( bank.wire_routing_number && bank.wire_routing_number !== '' ) {
    return <ForeignBankItem {...bank} />
  }

  if (bank.type === 'wallet') {
    return <WalletBankItem {...bank} />
  }

  return (
    <>
      <h4 className="text-center mt-3">
        <FormattedMessage id="deposit.option" values={{ d: bank.option }} />
      </h4>
      <ul className="listview flush transparent simple-listview no-space mt-1">
        <li>
          <strong>
            <FormattedMessage id="fields.bank" />
          </strong>
          <CopyContent text={bank.name + ' - ' + bank.code} />
        </li>
        <li>
          <strong>
            <FormattedMessage id="fields.agency" />
          </strong>
          <CopyContent text={bank.agency} />
        </li>
        <li>
          <strong>
            <FormattedMessage id="fields.account" />
          </strong>
          <CopyContent text={bank.account} />
        </li>
        <li>
          <strong>
            <FormattedMessage id="bank.select_bank_account" />
          </strong>
          {
            bank.type === 'corrente'
              ? <FormattedMessage id="bank.checking_acc" />
              : <FormattedMessage id="bank.saving_acc" />
          }
        </li>
      </ul>
    </>
  );
}

export default BankItem;