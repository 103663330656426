export type MaskProp = {
  mask?: 'currency:BRL' |
  'currency:USD' |
  'currency:USDT' |
  'currency:DRC' |
  'currency:BTC' |
  'phone' |
  'cep' |
  'cpf' |
  'cpfCnpj' | 
  'integer' |
  'cnpj' | 
  'phoneUS' |
  'date' |
  string
}

export const currencyBRLMask = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  return value;
}

export const currencyUSDMask = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1.$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ",");

  return value;
}

export const phoneMask = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  return value;
}

export const phoneUSMask = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{3})(\d)/, "($1) $2");
  value = value.replace(/(\d{3})(\d{1,4})$/, "$1-$2");
  return value;
}

export const cpfMask = (value: string) => {
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{3})(\d)/, "$1.$2")
  value = value.replace(/(\d{3})(\d)/, "$1.$2")
  value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return value;
}

export const dateMask = (value: string) => {
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{2})(\d)/, "$1/$2")
  value = value.replace(/(\d{2})(\d{1,4})$/, "$1/$2")
  return value;
}

export const cnpjMask = (value: string) => {
  value = value.replace(/\D/g, "")
  value = value.replace(/^(\d{2})(\d)/, "$1.$2")
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
  value = value.replace(/\.(\d{3})(\d)/, ".$1/$2")
  value = value.replace(/(\d{4})(\d)/, "$1-$2")
  return value;
}

export const cepMask = (value: string) => {
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{5})(\d)/, "$1-$2")
  return value;
}

export const integerMask = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)$/, "$1");
  return value;
}

export const currencyBTCMask = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{8})$/, "$1.$2");
  return value;
}

export const ssnMask = (value: string) => {
  value = value.replace(/\D/g,"");

  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{3})(\d)/, "$1-$2")
  value = value.replace(/(\d{2})(\d{1,4})$/, "$1-$2")

  // if (value.length > 9) {
  //   return value.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"$1-$2-$3");
  // }
  
  // if (value.length > 4) {
  //   return value.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"$1-$2-$3");
  // }
  
  // if (value.length > 2) {
  //   return value.replace(/^(\d\d\d)(\d{0,3})/,"$1-$2");
  // }

  return value;
}

export const applyMask = (value: string, config: MaskProp) => {
  
  switch(config.mask) {
    case 'currency:BRL':
      return currencyBRLMask(value);
    case 'currency:USD':
    case 'currency:USDT':
    case 'currency:DRC':
      return currencyUSDMask(value);
    case 'currency:BTC':
      return currencyBTCMask(value);
    case 'phone':
      return phoneMask(value);
    case 'date':
      return dateMask(value);
    case 'phoneUS':
      return phoneUSMask(value);
    case 'cep':
      return cepMask(value);
      case 'cnpj':
      return cnpjMask(value);
    case 'cpf':
      return cpfMask(value);
    case 'integer':
      return integerMask(value);
    case 'ssn':
      return ssnMask(value);
    case 'cpfCnpj':
      if ( value.length <= 14 ) {
        return cpfMask(value);
      }

      return cnpjMask(value);
    default:
      return value;
  }
}