import { navigationReducer, NavigationState } from "./reducers/navigationReducer";
import { combineReducers, createStore, compose } from 'redux';
import { userReducer, UserState } from "./reducers/userReducer";
import { clientReducer, ClientState } from "reducers/clientReducer";
import { cashoutsReducer, CashoutsState } from "reducers/cashoutReducer";

const rootReducer = combineReducers({
  navigation: navigationReducer,
  user: userReducer,
  client: clientReducer,
  cashouts: cashoutsReducer
});


export interface ApplicationState {
  navigation: NavigationState,
  user: UserState,
  client: ClientState,
  cashouts: CashoutsState
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers());

export default store;
