import React from 'react';
import { ColorsProps } from '../GeneralProps';

type ButtonProps = {
  title: string;
  type: 'submit'|'button';
  size?: 'lg'|'sm'|'md';
  block?: boolean;
  outline?: boolean;
  className?: string;
  text?: boolean;
  icon?: any,
  disabled?: boolean,
  loading?: boolean,
  onClick?: () => void
}

type Props = ButtonProps & ColorsProps;

const Button: React.FC<Props> = ({ color, title, type, size, block, outline, className, icon, onClick, text, loading, disabled }) => {
  
  const cssClasses = [
    'btn',
    'btn-' + (outline ? 'outline-' : (text ? 'text-' : '')) + color,
    size      && size !== 'md' ? 'btn-' + size : '',
    block     ? 'btn-block'                    : '',
    className ? className                      : ''
  ];

  return (
    <button type={type} className={cssClasses.join(' ')} disabled={loading || disabled} onClick={() => onClick ? onClick() : null}>
      {
        loading
          ? <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
          : null
      }
      { 
        icon
          ? icon
          : null
      }
      { title }
    </button>
  );
}

export default Button;