import { api, getRequestOptions } from "api";

export default class BankRequests {

    static list() {
      return api.get('/banks', getRequestOptions({ skipErrors: [404] }));
    }

    static codes() {
      return api.get('/banks/codes', getRequestOptions());
    }

    static create(data: any) {
      return api.post('/banks', data, getRequestOptions());
    }

    static createForeign(data: any) {
      return api.post('/banks/foreign', data, getRequestOptions());
    }

    static listWallets() {
      return api.get('/wallets', getRequestOptions({ skipErrors: [404] }));
    }

    static createWallet(data: any) {
      return api.post('/wallets', data, getRequestOptions());
    }

    static delete(id: number) {
      return api.delete('/banks/' + id, getRequestOptions());
    }

    static deleteWallet(id: number) {
      return api.delete('/wallets/' + id, getRequestOptions());
    }

    static mk(country: 'US'|'BR'|'WALLET'|'all') {
      return api.get('/banks/mk?country=' + country, getRequestOptions({ skipErrors: [404] }));
    }
    
}