import CashoutRequests from "api/CashoutRequests";
import Container from "common/components/Container";
import { Col, Row } from "common/components/ui/Layout";
import useTranslation from "hooks/useTranslation";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ClientTypes } from "reducers/clientReducer";
import CashoutInformation from "./components/CashoutInformation";
import CashoutReceipts from "./components/CashoutReceipts";


const CashoutDetails = () => {
  const i = useTranslation();
  const dispatch      = useDispatch();
  const { id }: any   = useParams();

  useEffect(() => {
    dispatch({ type: ClientTypes.UPDATE_CASHOUT, payload: {} })
    CashoutRequests.get(id)
      .then( response => dispatch({ type: ClientTypes.UPDATE_CASHOUT, payload: response.data  }))
      .catch( error => console.warn(error))
  }, [id, dispatch]);

  return (
    <Container title={process.env.REACT_APP_OFFICE_ID === '2' ? i._('cashout.new') : i._('cashout.withdraw')} goBackLink="/cashout">
      <Row>
        <Col sm={8}>
          <CashoutInformation />
        </Col>
        <Col sm={4}>
          <CashoutReceipts />
        </Col>
      </Row>
    </Container>
  )
}

export default CashoutDetails;