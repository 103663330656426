import { extractData } from "api";
import FundRequests from "api/FundRequests";
import Card from "common/components/ui/Card";
import { Col, Row } from "common/components/ui/Layout";
import LoadingSpinner from "common/components/ui/LoadingSpinner";
import { useTranslation } from "hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import FeedItem, { TFeed } from "./FeedItem";

const Feed = () => {
  const loader: any             = useRef();
  const [page, setPage]         = useState(0);
  const [feedData, setFeedData] = useState<null|TFeed[]>(null);
  const [error, setError]       = useState<false|number>(false);
  const [end, setEnd]           = useState(false);
  const i                       = useTranslation();


  useEffect(() => {
    function fetchFeed() {
      FundRequests.feed(page)
        .then(extractData)
        .then(data => {
          if ( data.length !== 20 ) {
            setEnd(true);
          }

          if ( data && data.length > 0 ) {
            setFeedData(feedData ? [...feedData, ...data] : data);
          }
        })
        .catch(err => setError(500))
    }

    fetchFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => setFeedData([]);
  }, [handleObserver]);

  return (
    <Row className="Feed">
      <Col xs={12}>
        <Card title={i._("feed.last_updates")} fullMobile>
            {
              feedData && !error
                ? feedData.map((fd, fdI) => <FeedItem {...fd} key={"f" + fdI} />)
                : null
            }
            {
              !end && !error
                ? <div className="text-center">
                    <LoadingSpinner />
                    <div ref={loader} className="mt-2"></div>
                  </div>
                : null
            }
            {
              end && feedData && feedData.length === 0 
                ? <div>
                    <p><FormattedMessage id="feed.no_records"/>.</p>
                  </div>
                : null
            }
        </Card>
      </Col>
    </Row>
  )
}

export default Feed;