import { api, getRequestOptions } from ".";

export default class DepositRequests {
    static create(data: any) {
        return api.post('/deposits', data, getRequestOptions());
    }

    static list(page: number = 0) {
        return api.get('/deposits?page=' + page, getRequestOptions({ skipErrors: [404] }));
    }

    static get(id: number) {
        return api.get('/deposits/' + id, getRequestOptions());
    }

    static uploadReceipt(data: FormData) {
        return api.post('/deposits/receipt', data, getRequestOptions());
    }

    static expirations(){
        return api.get('/deposits/expirations', getRequestOptions({ skipErrors: [404] }))
    }

    static renew(deposit_id: number, months: number){
        return api.post(`/deposits/${deposit_id}/renew`, { type: months }, getRequestOptions());
    }
}