import { api, getRequestOptions } from "api";

export default class ClientRequest {
    static get(id: number) {
        return api.get('/clients/' + id, getRequestOptions())
    }

    static update(id: number, data: any) {
        return api.put('/clients/' + id, data, getRequestOptions());
    }

    static amounts(id: number) {
        return api.get('/clients/' + id + '/amounts', getRequestOptions());
    }

    static totals(id: number) {
        return api.get('/clients/' + id + '/totals', getRequestOptions());
    }

    static uploadDocument(id: number, data: FormData) {
        return api.post('/clients/' + id + '/documents', data, getRequestOptions());
    }

    static documents(id: number) {
        return api.get('/clients/' + id + '/documents', getRequestOptions({ skipErrors: [404]}));
    }

    static toggleReinvest() {
        return api.post('/clients/toggle_reinvest', {}, getRequestOptions());
    }

    static stats(id: any) {
        return api.get('/clients/' + id + '/stats', getRequestOptions({ skipErrors: [404]}));
    }

    static changeLocale(locale: string){
        return api.post('/clients/locale', { locale }, getRequestOptions());
    }
    
    static create(client: any) {
        return api.post('/onboarding', client, getRequestOptions({ skipErrors: [403, 400] }));
    }

    static onboarding(code: string) {
        return api.get('/onboarding/check/' + code, getRequestOptions());
    }
}