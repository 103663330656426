

export const ColorsSBA: any =  {
  PRIMARY: '#133751',
  PRIMARY_LIGHT: '#82a7c2',
  SECONDARY: '#0e283b',
  SUCCESS: '#1DCC70',
  ERROR: '#FF396F',
  WARNING: '#FFB400',
  ACCENT: '#d9a556',
  ACCENT_DARK: '#b88a45',
}

export const ColorsARB: any =  {
  PRIMARY: '#272a2c',
  PRIMARY_LIGHT: '#82a7c2',
  SECONDARY: '#141516',
  SUCCESS: '#1DCC70',
  ERROR: '#FF396F',
  WARNING: '#FFB400',
  ACCENT: '#DBBD89',
  ACCENT_DARK: '#96713a',
}

export const Colors = process.env.REACT_APP_OFFICE_ID === '2' ? ColorsARB : ColorsSBA;

export enum Members {
  THAYNNA = 4378,
  LUCAS = 7082
}

export const MEMBERS_ACCESS = [Members.LUCAS, Members.THAYNNA];

export const MAINTENANCE_MODE = false;

export const ARBTRUST_URL          = 'https://client.arbtrust.fund';
export const API_URL               = process.env.REACT_APP_OFFICE_ID === '2' ? 'http://api.v2.mkcapital.io/api/v2' : 'http://api.v2.mkcapital.io/api/v2';
export const OLD_APP_URL           = process.env.REACT_APP_OFFICE_ID === '2' ? 'https://app.arbtrust.fund' : 'https://app.sbarainicapital.com.br';
export const FILE_URL_PREFIX       = OLD_APP_URL + '/files/static';
export const RECEIPT_FILES_PREFIX  = FILE_URL_PREFIX + '/receipt?path=';
export const SELLER_FILES_PREFIX   = FILE_URL_PREFIX + '/seller?path='
export const INTERNAL_FILES_PREFIX = FILE_URL_PREFIX + '/internal?path=';
export const APP_LEGAL_NAME        = process.env.REACT_APP_OFFICE_ID === '2' ? 'ARBTRUST' : 'SBARAINI CAPITAL';
export const APP_LEGAL_NAME_F      = process.env.REACT_APP_OFFICE_ID === '2' ? 'Arbtrust' : 'Sbaraini Capital';

export const AVAILABLE_BR_STATES = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];

export const AVAILABLE_US_STATES: any = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District of Columbia",
  "FM": "Federated States of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam Gu",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming",
}