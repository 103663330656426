import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { IoAlertCircle, IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
type Props = {
  onClose?: () => void;
  onConfirm?: () => void;
  type?: 'success' | 'error' | 'warning'| null;
  title: string;
  message: string;
  success?: boolean
  successButtonText?: string|null;
  successLink?: string|null
  cancelButtonText?: string|null;
};

const Dialog: React.FC<Props> = ({ onConfirm, onClose, type, title, message, successButtonText, cancelButtonText, successLink, success = true }) => {
  const history             = useHistory();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setTimeout(() => setOpened(true), 80);
  }, []);

  const closeDialog = () => {
    setOpened(false);
    setTimeout(() => onClose ? onClose() : null, 80);
  };

  const successRedirect = () => {
    
    if ( onConfirm ) {
      onConfirm();
    } else if ( typeof successLink === 'string' && successLink !== '' ) {
      closeDialog();
      history.push(successLink);
    } else {
      closeDialog();
    }
  }

  return ReactDOM.createPortal(
    <>
      <div  className={"modal fade dialogbox master-dialog " + (opened ? 'show' : '')} 
            style={{ display: (opened ? 'block' : 'unset') }} 
            id="DialogIconedButtonInline" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {
              type === 'success'
                ? <div className="modal-icon text-success">
                      <IoCheckmarkCircle height="65px" width="65px" color="#1DCC70" />
                  </div>
                : null
            }
            {
              type === 'error'
                ? <div className="modal-icon text-danger">
                      <IoCloseCircle color="#FF396F" height="65px" width="65px" />
                  </div>
                : null
            }
            {
              type === 'warning'
                ? <div className="modal-icon text-warning">
                      <IoAlertCircle color="#FFB400" height="65px" width="65px" />
                  </div>
                : null
            }
            {
              title 
                ? <div className="modal-header">
                    <h5 className="modal-title">{ title }</h5>
                  </div>
                : null
            }
            <div className="modal-body" dangerouslySetInnerHTML={{ __html: message }}>
            </div>
            <div className="modal-footer">
              <div className="btn-inline">
                <div className="btn btn-text-danger" onClick={closeDialog}>
                  { 
                    cancelButtonText 
                      ? cancelButtonText 
                      : <FormattedMessage id="global.cancel" />
                  }
                </div>
                {
                  success 
                    ? <div className="btn btn-text-primary" onClick={successRedirect}>
                        { 
                          successButtonText 
                            ? successButtonText 
                            : <FormattedMessage id="global.confirm" /> 
                        }
                      </div>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"modal-backdrop fade master-dialog " + (opened ? 'show' : '')}></div>
    </>
  , document.body);
}

export default Dialog;