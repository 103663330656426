import React, { FormEvent, useRef, useState } from "react";
import { Button } from "common/components/ui/Form";
import LoadingSpinner from "common/components/ui/LoadingSpinner";
import DepositRequests from "api/DepositRequests";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ClientTypes } from "reducers/clientReducer";
import { Deposit } from "types/Deposit";

import "../deposit.scss";
import { FormattedMessage } from "react-intl";
import { IoCloudUploadOutline, IoOpenOutline } from "react-icons/io5";
import useTranslation from "hooks/useTranslation";
import { RECEIPT_FILES_PREFIX } from "types/config";
import { getDecodedJWT } from "utils";

type ReceiptOpenButtonProps = {
  deposit: Deposit;
  size: 'md'|'lg';
}

type ReceiptPreviewProps = {
  link: string
}

export const ReceiptOpenButton: React.FC<ReceiptOpenButtonProps> = ({ deposit, size }) => {
  const i = useTranslation();

  const openReceipt = () => {
    getDecodedJWT()
      .then((decoded: any) => {
        const depositURL = RECEIPT_FILES_PREFIX + deposit.receipt[deposit.receipt.length - 1].file + '&token=' + decoded.sat;
        window.open(depositURL, '_blank')?.focus();
      })
  }

  return (
    <Button title={i._('deposit.open_receipt')} icon={<IoOpenOutline color="white" />} 
            onClick={openReceipt} size={size ? size : 'md'} type="button" color="success" block className="mb-1" />
  );
}

export const ReceiptPreview: React.FC<ReceiptPreviewProps> = () => {
  const deposit = useSelector((state: ApplicationState) => state.client.deposit);

  if ( deposit.receipt.length > 0 ) {
    return (
      <div className="receipt-preview my-3">
        <ReceiptOpenButton deposit={deposit} size="md" />
      </div>
    );
  } else {
    return null;
  }

}

const DepositReceipt = ({ ...props }) => {
  const i = useTranslation();
  const [error, setError]     = useState('');
  const [loading, setLoading] = useState(false);
  const fileUploadRef: any    = useRef();
  const dispatch              = useDispatch();
  const deposit               = useSelector((state: ApplicationState) => state.client.deposit);

  const openFileExplorer = () => {
    fileUploadRef.current.click();
  }

  const onFileChange = (e: FormEvent<HTMLInputElement>) => {
    setError('');
    
    if ( e.currentTarget.files?.length === 1 ) {
      const file = e.currentTarget.files[0];
      if ( (file.size / 1024) > 2048 ) {
        e.currentTarget.value = '';
        setError('O arquivo não pode ser maior que 2MB');
        return;
      }

      sendFile(file);
    }
  }

  const sendFile = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    if ( deposit.id ) {
      const depositID = parseInt(deposit.id);
      formData.append('object_id', depositID.toString());
      setLoading(true);
     
      DepositRequests.uploadReceipt(formData)
        .then( depositResponse => DepositRequests.get(depositID))
        .then( response => dispatch({ type: ClientTypes.UPDATE_DEPOSIT, payload: response.data }))
        .catch( error => console.warn('Erro ao enviar arquivo'))
        .finally(() => setLoading(false));
    }
  }

  if ( loading ) {
    return (
      <div className="inline-spinner">
        <LoadingSpinner />
        <FormattedMessage id="global.sending" />
      </div>
    );
  }

  return (
    <>
      {
        deposit.receipt.length > 0
          ? <ReceiptOpenButton deposit={deposit} size="lg" />
          : null
      }
      {
        deposit.status === 0
          ? <form style={{ width: '100%'}}>
              <input type="file" onChange={onFileChange} className="d-none" ref={fileUploadRef} />
              <Button 
                title={i._('deposit.send_receipt')}
                onClick={openFileExplorer} color="primary" size="lg" type="button" icon={<IoCloudUploadOutline size={20}color="white" />} block />
              {
                error && error !== ''
                  ? <small className="text-danger">{ error }</small>
                  : null
              }
            </form>
          : null
      }
      
    </>
  );
}

export default DepositReceipt;