import AppStorage from "./AppStorage";
import jwt_decode from "jwt-decode";

export const readJWT = (token: string) => new Promise((resolve, reject) => {
  AppStorage.set('token', token);
  try {
    const decoded: any = jwt_decode(token);
    resolve(decoded);
  } catch ( error ) {
    reject(false);
    return;
  }  
});

export const forceJWT = (token: string) => AppStorage.set('token', token);

export const getCurrentFundClient = () => {
  const fund = AppStorage.get('fcl');
  return fund ? parseInt(fund) : 0;
}

export const setCurrentFundClient = (id: number) => {
  AppStorage.set('fcl', id.toString());
}

export const getDecodedJWT = () => new Promise((resolve, reject) => {
  const token = AppStorage.get('token');

  if ( token ) {
    try {
      const decoded = jwt_decode(token);
      resolve(decoded);
      return;
    } catch ( error ) {
      reject(false);
      return;
    }
  } else {
    reject(false)
  }
  
});