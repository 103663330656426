import React from "react";
import { IoSwapHorizontal } from "react-icons/io5";

type Props = {
  size?: number
  color?: string
}

const LiquidezLogo: React.FC<Props> = ({ size, color = 'white' }) => {
  return (
    <IoSwapHorizontal size={size} />
  );
}

export default LiquidezLogo;