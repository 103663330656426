import { useSelector } from "react-redux";
import { Reducer } from "redux";
import { ApplicationState } from "store";
import { ColorsProps } from "../common/components/ui/GeneralProps";

interface Error {
  message: string|null
}

export interface UserState {
  authUser: any|null,
  currencyType: 'BTC'|'BRL'|'USD'|'DRC'
  authError: Error & ColorsProps,
  formCurrency: any,
  totals: any,
  beta: boolean
}

export enum UserTypes {
  UPDATE_AUTH_USER         = '@@user/UPDATE_AUTH_USER',
  UPDATE_AUTH_USER_AMOUNTS = '@@user/UPDATE_AUTH_USER_AMOUNTS',
  UPDATE_AUTH_ERROR        = '@@user/UPDATE_AUTH_ERROR',
  LOGOUT                   = '@@user/LOGOUT',
  UPDATE_LOGIN_FORM        = '@@user/UPDATE_LOGIN_FORM',
  UPDATE_CURRENCY_TYPE     = '@@user/UPDATE_CURRENCY_TYPE',
  RESET_LOGIN_FORM         = '@@user/RESET_LOGIN_FORM',
  UPDATE_TOTALS            = '@@user/UPDATE_TOTALS',
  UPDATE_FORM_CURRENCY     = '@@user/UPDATE_FORM_CURRENCY',
  UPDATE_USER_NAME         = '@@user/UPDATE_USER_NAME',
  UPDATE_REINVEST          = '@@user/UPDATE_REINVEST',
  UPDATE_BETA              = '@@user/UPDATE_BETA',
  RESET                    = '@@user/RESET'
}

const INITITAL_STATE: UserState = {
  authUser: null,
  currencyType: 'BRL',
  formCurrency: 'BRL',
  totals: null,
  beta: false,
  authError: {
    message: null,
    color: 'light',
  }
}

export const userReducer: Reducer<UserState> = (state = INITITAL_STATE, action) => {
  switch ( action.type ) {
    case UserTypes.UPDATE_AUTH_USER:
      return {
        ...state,
        authUser: action.payload
      };
    case UserTypes.UPDATE_AUTH_ERROR:
      return {
        ...state,
        authError: action.payload
      };
    case UserTypes.UPDATE_AUTH_USER_AMOUNTS:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          useramount: action.payload
        }
      };
    case UserTypes.LOGOUT:
      return {
        ...state,
        authUser: null,
        authError: action.payload
      };
    case UserTypes.UPDATE_CURRENCY_TYPE:
      return {
        ...state,
        currencyType: action.payload
      };
    case UserTypes.UPDATE_TOTALS:
      return {
        ...state,
        totals: action.payload
      };
    case UserTypes.UPDATE_BETA:
      return {
        ...state,
        beta: action.payload
      };
    case UserTypes.UPDATE_REINVEST:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          reinvest: action.payload
        }
      };
    case UserTypes.UPDATE_FORM_CURRENCY:
      return {
        ...state,
        formCurrency: action.payload
      };
    case UserTypes.UPDATE_USER_NAME:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          name: action.payload
        }
      };
    case UserTypes.RESET:
      return INITITAL_STATE;
    default:
      return state;
  }
}

export const useAuth = () => useSelector((state: ApplicationState) => state.user.authUser);
export const useClientTotals = () => useSelector((state: ApplicationState) => state.user.totals);
export const useCurrencyType = () => useSelector((state: ApplicationState) => state.user.currencyType);
export const useFormCurrencyType = () => useSelector((state: ApplicationState) => state.user.formCurrency);
export const useAmounts = () => useSelector((state: ApplicationState) => state.user.authUser?.useramount ? state.user.authUser.useramount[0] : null);

// Return true if user is in beta list
export const useBeta = () => useSelector((state: ApplicationState) => state.user.beta);