import React, { useEffect, useState } from "react";
import { ValuesType } from "./LatamForm";
import useDebounce from "hooks/useDebounce";
import LatamRequests from "api/LatamRequests";
import { extractData } from "api";
import { CurrencyUtils } from "utils";
import RectLoader from "common/components/RectPlaceholder";
import { Col, Row } from "common/components/ui/Layout";

type LatamQuoteEstimateProps = {
    value: string,
    onCalculate: (values: ValuesType) => void
}

const SERVICE_TAX = 3.5;
const LatamQuoteEstimate: React.FC<LatamQuoteEstimateProps> = ({ value, onCalculate }) => {
    const [loadingPrice, setLoadingPrice] = useState(true);
    const [toValue, setToValue] = useState(0);
    const [last, setLast] = useState(0);
    const [fromValue, setFromValue] = useState(0);
    const quoteValue = useDebounce(toValue, 2600);

    useEffect(() => {
        setLoadingPrice(true);
        // eslint-disable-next-line 
        setToValue(parseFloat(value.replace(/\,/g, '')))
    }, [value])

    useEffect(() => {
        setLoadingPrice(true)
        LatamRequests.price()
            .then(extractData)
            .then(response => {
                setFromValue((toValue * response.last) + SERVICE_TAX);
                setLast(response.last);
                onCalculate({
                    price: response.last,
                })
            })
            .catch(err => console.log(err))
            .finally(() => setLoadingPrice(false))
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteValue]);

    return (
        <Row>
            <Col md={12} className="d-flex justify-content-between">
                <div>
                    BRL Quoted Value: <br />
                </div>
                <div className="d-flex flex-column align-items-end">
                    {
                        !loadingPrice && last > 0 && fromValue > SERVICE_TAX
                            ? <strong>{ CurrencyUtils.format(fromValue, 'BRL') }</strong>
                            : <RectLoader width={80} height={22} />
                    }
                </div>
            </Col>
        </Row>
    )
}

export default LatamQuoteEstimate;