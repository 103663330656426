import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { IoCloseOutline } from "react-icons/io5";

type Props = {
  title?: string,
  children?: any,
  modalMode?: boolean,
  size?: 'md'|'sm'
  show?: boolean,
  onClose?(): void,
  footerContent?: any,
  scrollable?: boolean,
  top?: string
}

const ActionSheet: React.FC<Props> = ({ title, children, show, onClose, size = 'sm', footerContent, modalMode = false, scrollable = false , top}) => {

  const [open, setOpen] = useState(false);

  const hide = useCallback((callFunction = true) => {
    setOpen(false);
    setTimeout(() => {
      if ( onClose && callFunction ) onClose();
      document.body.style.overflow = 'unset';
    }, 150);
  }, [onClose]);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
      if ( !modalMode ) document.body.style.overflow = 'hidden';
    }, 150);

    return () => { document.body.style.overflow = 'unset' };
  }, [show, hide, modalMode]);


  return ReactDOM.createPortal(
    <>
      <div className={"modal fade action-sheet" + (open ? ' show' : '') + ' ' + size} id="actionSheetIconed" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document" style={top ? {top: top} : {}}>
          <div className="modal-content">
            { 
              title 
                ? <div className="modal-header">
                    <h5 className="modal-title">{ title } <IoCloseOutline size={20} onClick={() => hide()} /></h5>
                  </div>
                : null
            }
            <div className="modal-body" style={scrollable ? { maxHeight: '90vh', overflowY: 'scroll' } : {}}>
              { children }
            </div>
            {
              footerContent
                ? <div className="modal-footer">
                    { footerContent }
                  </div>
                : null
            }
          </div>
        </div>
      </div>
      {
        open
          ? <div className={"modal-backdrop fade " + (open ? 'show' : '')} onClick={() => hide()}></div>
          : null
      }
    </>
  , document.body);
}

export default ActionSheet;