import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import { ApplicationState } from "store";

/**
 * Usefull form states
 */
const useForm = () => {

  const dispatch = useDispatch();
  const [dataLoading, setDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const alert = useSelector((state: ApplicationState) => state.navigation.alert);

  const data = function(){};
  const submit = function(){};

  data.loading    = dataLoading;
  submit.loading  = submitLoading;

  const showAlert = useCallback((message: string|boolean, color: string = 'success') => {
    if ( message === false ) {
      dispatch({
        type: NavigationTypes.UPDATE_ALERT,
        payload: false
      });
      return;
    }

    dispatch({
      type: NavigationTypes.UPDATE_ALERT,
      payload: {
        message,
        color
      }
    });
  }, [dispatch]);

  const dataStart    = useCallback(() => setDataLoading(true), []);
  const dataStop     = useCallback(() => setDataLoading(false), []);
  const _submitStart  = useCallback(() => setSubmitLoading(true), []);
  const _submitStop   = useCallback(() => setSubmitLoading(false), []);
  const resetAlert    = useCallback(() => showAlert(false), [showAlert]);

  return {
    data: {
      loading: dataLoading,
      start: dataStart,
      stop: dataStop
    },
    submit: {
      loading: submitLoading,
      start: _submitStart,
      stop: _submitStop
    },
    resetAlert,
    dataStart,
    dataStop,
    alert,
    showAlert
  }
}

export default useForm;