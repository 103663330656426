import ClientRequest from "api/ClientRequest";
import FundRequests from "api/FundRequests";
import Container from "common/components/Container";
import CurrencySwitcher from "common/components/CurrencySwitcher";
import HiddenValues from "common/components/HiddenValues";
import Card from "common/components/ui/Card";
import { Col, Row } from "common/components/ui/Layout";
import LoadingSpinner from "common/components/ui/LoadingSpinner";
import { useTranslation } from "hooks";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavigationTypes } from "reducers/navigationReducer";
import { useAuth, useCurrencyType, UserTypes } from "reducers/userReducer";
import { ARBTRUST_URL, Colors } from "types/config";
import { setCurrentFundClient } from "utils/AuthUtils";
import Feed from "./components/Feed";
import FundAmount from "./components/FundAmount";
import FundItem from "./components/FundItem";
import OverviewPie from "./components/OverviewPie";


const mapToPieData = (amounts: any, currency: string) => {
  return amounts.map((f: any) => {
    return {
      name: f.fund.name,
      description: f.fund.description,
      id: f.fund.id,
      value: f.amount[currency],
      fill: Colors.PRIMARY,
      fillHover: Colors.SECONDARY
    }
  });
}

const isEmptyTotals = (totals: any) => {
  let empty = true;

  totals.forEach((t: any) => {
    if ( t.value > 0 ) {
      empty = false;
    }
  });

  return empty;
}

const Overview = () => {

  const [activeFund, setActiveFund]   = useState(2);
  const [amounts, setAmounts]         = useState<any[]|null>(null);
  const [totalAmounts, setTotalAmounts] = useState<any|null>(null);
  const [pieData, setPieData]         = useState<any[]|null>(null);
  const authUser                      = useAuth();
  const history                       = useHistory();
  const dispatch                      = useDispatch();
  const currencyType                  = useCurrencyType()
  const i                             = useTranslation();

  useEffect(() => {
    async function fetchAmounts() {
      try {
        const { data: dataAmounts } = await FundRequests.amounts();
        setAmounts(dataAmounts);

        const { data: dataTotalAmounts } = await FundRequests.totalAmount();
        setTotalAmounts(dataTotalAmounts);

      } catch ( err ) {
        console.log(err);
      }
    }

    fetchAmounts();
  }, []);


  useEffect(() => {
    if ( amounts ) {
      const mappedData = mapToPieData(amounts, currencyType.toLowerCase());
      if ( isEmptyTotals(mappedData) ) {
        setPieData([{ name: i._('global.no_data'), value: 100.07, fill: '#A9ABAD', hidden: false, id: 0 }]);
      } else {
        setPieData(mapToPieData(amounts, currencyType.toLowerCase()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyType, amounts]);

  const setFund = async (fund: any) => {
    setCurrentFundClient(fund.client_id);
    try {
      const { data } = await ClientRequest.get(fund.client_id)
      dispatch({ type: UserTypes.UPDATE_AUTH_USER, payload: data });
      dispatch({ type: NavigationTypes.UPDATE_FUND, payload: fund.fund });
      history.push('/');
    } finally { }
  }

  const onOpenFundPreview = (id: number) => {
    setActiveFund( activeFund !== id ? id : 0);
  }

  return (
    <Container className="overview-container" sidebar={false} fundSwitcher={false} title={i._("overview.select_waller")}>
      <div className="container">
        <div className="d-none d-md-block">
          <h2><FormattedMessage id="global.hello"/> { authUser.name.split(' ')[0] }, </h2>
          <h4><FormattedMessage id="overview.summary_text"/></h4>
        </div>
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={4}>
            <Card fullMobile className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="pt-1">
                  <FormattedMessage
                    id="global.total_balance"
                    defaultMessage="Balance" />
                  <br/>
                  {
                    totalAmounts && totalAmounts[currencyType] 
                    ? <>
                          <FundAmount key="totalAmount" value={totalAmounts[currencyType]} currencyType={currencyType}/>
                      </>
                    : null
                  }
                  </h5>
                  <HiddenValues className="mx-2" color="primary" />
                </div>
            </Card>
            <Card fullMobile className="" >
              <div className="d-flex justify-content-between align-items-center">
                {
                  pieData && amounts && pieData.length > 0 
                    ? <>
                        { 
                          pieData.map(
                            (a, aI) => activeFund === a.id && a.value !== 100.07
                            ? <>
                                <h4 className="pt-1">
                                  <FormattedMessage
                                  id="global.u_balance" values={{c: i._('fund.' + a.name) }}
                                  defaultMessage="Balance" />
                                </h4>
                                <FundAmount key={"aa" + aI} value={a.value} currencyType={currencyType} /> 
                              </>
                            : null
                          )
                        }
                      </>
                    : null
                }
              </div>
              {
                pieData && pieData.length > 0
                  ? <OverviewPie active={activeFund} data={pieData} onMouseEnter={i => setActiveFund(i)} />
                  : <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 300 }}><LoadingSpinner /></div>
              }
              
              <CurrencySwitcher />
            </Card>
          </Col>
          <Col xs={12} md={8}>
            <Card fullMobile>
              <p className="d-none d-md-block">
                <FormattedMessage id="overview.p_text"/> 
              </p>

              {
                authUser.available_funds.map((f: any, indx: number) => <FundItem key={'f_'+f.fund.id} 
                  onClick={() => onOpenFundPreview(f.fund.id)}
                  active={activeFund === f.fund.id}
                  currency={currencyType}
                  onFundSelect={(fund) => setFund(fund)}
                  fund={f} />)
              }
              {
                // Se for Sbaraini e for movimentado para Arbtrust
                process.env.REACT_APP_OFFICE_ID === '1' && authUser.arbtrust_fund
                  ? <FundItem key={'f_'+4} 
                      onClick={() => onOpenFundPreview(4)}
                      active={activeFund === 4}
                      currency={'USD'}
                      backgroundColor="#333333"
                      buttonLink={ARBTRUST_URL + "/auth/login?p=" + authUser.email}
                      onFundSelect={(fund) => setFund(fund)}
                      fund={
                        {
                          client_id: -1,
                          fund: {
                            id: 4,
                            name: 'american_fund',
                            description: '',
                            shortage: 0,
                            initial_deposit: 0,
                            cashout_days: 0,
                            tax_description: 'OOO'
                          }
                        }
                      } />

                  : null
              }
            </Card>
          </Col>
        </Row>
        <Feed />
      </div>
    </Container>
  )
}

export default Overview;