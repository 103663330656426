import React from "react";
import { IoStarOutline } from "react-icons/io5";

type Props = {
  size?: number,
  color?: string
}

const FirstLogo: React.FC<Props> = ({ size, color = 'white' }) => {
  return (
    <IoStarOutline size={size} />
  )
}

export default FirstLogo;