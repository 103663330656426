export default class AppStorage { 
  static set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static get(key: string) {
    return localStorage.getItem(key);
  }

  static delete(key: string) {
    localStorage.removeItem(key);
  }
}