import React, { useEffect } from 'react';
import Header from './Header';
import "./container.scss";
import PageHeader, { PageHeaderProps } from './PageHeader';
import Sidebar from './Sidebar';
import Toast from './ui/Toast';
import BottomMenu from './BottomMenu';
import { ApiError } from 'reducers/navigationReducer';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ErrorMessage from './ErrorMessage';

type ContainerProps = {
  children?: any;
  full?: boolean;
  className?: string,
  contentClass?: string
  extraHeader?: boolean;
  sidebar?: boolean
  fundSwitcher?: boolean;
}

type Props = ContainerProps & PageHeaderProps;

const Container: React.FC<Props> = ({ 
  children, 
  full, 
  title, 
  goBackLink, 
  rightHeader, 
  contentClass,
  className = '',
  extraHeader, 
  sidebar = true,
  fundSwitcher = true
}) => {
  
  const apiError: ApiError = useSelector((state: ApplicationState) => state.navigation.apiError);

  useEffect(() => {
    if ( full ) {
      document.body.classList.add('bg-white');
    } else {
      document.body.classList.remove('bg-white');
    }
  }, [full]);

  return (
    <div>
      {
        sidebar
          ? <Sidebar /> 
          : null
      }
      
      <div className={"content " + className} >
        <Header fundSwitcher={fundSwitcher} extraHeader={extraHeader} sidebar={sidebar} profileOptions={sidebar ? undefined : []} />
        <Toast />
        <div className="container-header" style={sidebar ? {} : { marginLeft: 0 }}>
          {
            title 
              ? <PageHeader title={title} goBackLink={goBackLink} rightHeader={rightHeader} />
              : null
          }
          <div className={"container-content " + contentClass}>
            { 
              apiError.status
                ? <ErrorMessage />
                : children 
            }
          </div>
        </div>
        {
          sidebar
            ? <BottomMenu />
            : null
        }
      </div>
    </div>
  );
}

export default Container;