import CopyContent from "common/components/CopyContent";
import React from "react";
import { FormattedMessage } from "react-intl";

const WalletBankItem = (bank: any) => {
  return (
    <>
      <h4 className="text-center mt-3">
        <FormattedMessage id="deposit.option" values={{ d: bank.option }} />
      </h4>
      <ul className="listview flush transparent simple-listview no-space mt-1">
        <li>
          <strong>
            <FormattedMessage id="bank.network" />
          </strong>
          {bank.network}
        </li>
        <li>
          <strong>
            <FormattedMessage id="fields.address" />
          </strong>
          <CopyContent text={bank.code} />
        </li>
      </ul>
    </>
  )
}

export default WalletBankItem;

