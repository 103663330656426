import React, { useState } from "react";
import LatamForm from "./LatamForm";
import LatamQRCode from "./LatamQRCode";
import LatamError from "./LatamError";
import { useDispatch } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import LatamSuccess from "./LatamSuccess";

type Props = {
    onReset: () => void
}

const Latam: React.FC<Props> = ({ onReset }) => {

    const [pixDetails, setPixDetails] = useState(null);
    const [error, setError] = useState<string|null>(null);
    const [success, setSuccess] = useState<any>(null);
    const dispatch = useDispatch();
    
    const _onReset = () => {
        setPixDetails(null);
        setError(null);

        dispatch({ type: NavigationTypes.TOGGLE_FOCUS_MODE, payload: false });

        onReset();
    }

    return (
        <div className="Latam">      
            { !success && !error && !pixDetails && <LatamForm onSubmit={prices => setPixDetails(prices)} /> }
            { !success && !error && pixDetails && <LatamQRCode onSuccess={o => setSuccess(o)} onTimeout={() => setError("Payment timeout")} pixDetails={pixDetails} />}
            { !success && error && <LatamError onReset={_onReset} error={error} /> }
            { success && <LatamSuccess onReset={_onReset} /> } 
        </div>
    )
}

export default Latam;
