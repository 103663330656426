
export type Deposit = {
  type?: 'BRL'|'BTC'|'USD'|'USDT',
  value?: number,
  created_at?: string,
  id?: string,
  status?: number,
  receipt: any[],
  detailts?: string,
  sub_type?: string
}

export type CashoutTransaction = {
  bank: any,
  date: string,
  liquid_value: number
}

export type Cashout = {
  type?: 'BRL'|'BTC'|'USD',
  value?: number,
  created_at?: string,
  id?: string,
  status?: number,
  receipts: any[],
  transaction?: CashoutTransaction
}


export type Document = {
  id: number,
  name: string,
  file: string
  status: number
  description: string,
  user_id: number,
  created_at: string
}

export type Payment = {
  id: number
  type: 'BTC'|'USD'|'BRL'|undefined,
  status: number,
  reference: string|null,
  value: number,
  deposit_id: number,
  admin_id: number,
  liquid_value?: number,
  unify?: Payment
}

export type Bank = {
  id: number,
  code: string,
  name: string,
  agency: string,
  account: string,
  operation: number,
  type: number,
  client_id: number
}

export type Fund = {
  id?: number,
  name: string,
  description: string,
  shortage: number,
  initial_deposit: number,
  cashout_days: number,
  tax_description: string
}

export const DepositUtils = {
  getStatusName: (status: number) => {
    switch ( status ) {
      case 0:
        return 'status.pending';
      case 1: 
        return 'status.confirm';
      case 2: 
        return 'status.canceled';
      case 4:
        return 'status.pending'
      case 5:
        return 'status.analysis'
      default:
        return 'status.confirm';
    }
  }
}