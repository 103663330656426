import { api, getDefaultConfig, getRequestOptions } from ".";


export default class authRequests {
  
  static login(loginForm: any) {
    return api.post('/auth/login', loginForm, { headers: getDefaultConfig() });
  }

  static requestRecovery(email: any) {
    return api.post('/auth/request_recovery', { email }, { headers: getDefaultConfig() })
  }

  static updatePassword(data: any) {
    return api.post('/auth/change_password', data, { headers: getDefaultConfig() });
  }

  static gtRegister() {
    return api.get('/auth/gt_register', { headers: getDefaultConfig() });
  }

  static verify() {
    return api.post('/auth/verify', {}, getRequestOptions());
  }

  static emailVerify(email: string) {
    return api.get('/auth/email_verify?email=' + email, { headers: getDefaultConfig() });
  }

}