import CashoutRequests from "api/CashoutRequests";
import ClientRequest from "api/ClientRequest";
import Alert from "common/components/ui/Alert";
import Dialog from "common/components/ui/Dialog";
import { Button, RadioInput, TextInput } from "common/components/ui/Form";
import { Col, Row } from "common/components/ui/Layout";
import useForm from "hooks/useForm";
import useTranslation from "hooks/useTranslation";
import useValidator from "hooks/useValidation";
import React, { FormEvent, SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ClientTypes } from "reducers/clientReducer";
import { useCurrentFund } from "reducers/navigationReducer";
import { useAuth, UserTypes } from "reducers/userReducer";
import AmountService from "services/AmountService";
import { getDecodedJWT } from "utils";
import CurrencyUtils from "utils/CurrencyUtils";
import CashoutAvailableAmount from "./CashoutAvailableAmount";
import TaxEstimate from "./TaxEstimate";

const INITIAL_FORM_STATE = {
  value: '',
  type: 'BRL'
};

const DEFAULT_VALIDATION = {
  value: ['required', 'gt0']
}


const CashoutForm = () => {
  const i = useTranslation();
  const dispatch                            = useDispatch();
  const authUser                            = useAuth();
  const history                             = useHistory();
  const [dialog, setDialog]                 = useState(false);
  const { alert, showAlert }                = useForm();
  const [formState, setFormState]           = useState(INITIAL_FORM_STATE);
  const [loading, setLoading]               = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [lastCashoutID, setLastCashoutID]   = useState(null);
  const {errors, validate, updateConfig}    = useValidator(DEFAULT_VALIDATION, {
    lte: i._('validation.lower_available')
  });

  const fund = useCurrentFund();

  // Get user amounts 
  useEffect(() => {
    if ( authUser?.id ) {
      ClientRequest.amounts(authUser.id)
        .then( response => {
          dispatch({
            type: UserTypes.UPDATE_AUTH_USER_AMOUNTS,
            payload: [response.data]
          });
        })
        .catch( error => console.warn('Erro'))
    }
  }, [authUser?.id, dispatch, fund]);

  useEffect(() => {
    if ( authUser ) {
      setFormState({ ...formState, type: CurrencyUtils.getFirstCurrency(authUser) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function checkIfHasPermission() {
      const decoded: any = await getDecodedJWT();
      if (decoded && decoded.frc) {
        setDisabledButton(true);
      }
    }

    checkIfHasPermission();
  }, []);

  // Dynamic Validation
  useEffect(() => {
    updateConfig({
      ...DEFAULT_VALIDATION,
      value: ['required', 'lte:' + authUser?.useramount[0][formState.type.toLowerCase()]]
    });
  }, [formState.type, updateConfig, authUser?.useramount, fund]);
  
  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    if ( name === 'type' ) {
      setFormState({ value: '', type: value });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  }
  
  const resetForm = () => setFormState(INITIAL_FORM_STATE);
  
  // After confirm dialog
  const onConfirm = async () => {
    setLoading(true)
    setDialog(false);

    const formData = {
      ...formState,
      value: formState.type === 'USD' || formState.type === 'USDT' ? CurrencyUtils.usdToReal(formState.value) : formState.value
    }

    try {
      const { data } = await CashoutRequests.create(formData);
      setLastCashoutID(data.id);
      await AmountService(dispatch).refresh(authUser.id);
      
      dispatch({ type: ClientTypes.ADD_CASHOUT, payload: data });
      setDialog(true);
      resetForm();
    } catch ( error: any ) {
      if ( error?.status === 400 ){
        if(error.data.message === 'MIN_VALUE' && process.env.REACT_APP_OFFICE_ID === '2'){
          showAlert(i._('cashout.min_value', {v: '$500.00'}), 'danger');
        }else{
          showAlert('cashout.no_funds', 'danger');
        }
      } 
    } finally {
      setLoading(false);
    }
  }

  // On click button to request cashout
  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLastCashoutID(null);

    try {
      await validate({ ...formState, value: CurrencyUtils.toNumber(formState.value, formState.type) });
      setDialog(true);
    } catch ( error: any ) {
      setLoading(false);
    }
  }

  const onVisualize = () => {
    dispatch({ type: ClientTypes.UPDATE_CASHOUT_FORM, payload: false });
    history.push('/cashout/' + lastCashoutID);
  }

  const onCancel = () => {
    setDialog(false);
    setLoading(false);
  }

  const userAmounts: any = authUser?.useramount[0];

  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Col md={12}>
          <p>
            <FormattedMessage id="deposit.choose_currency" />
          </p>
        </Col>
      </Row>
      <Row>
        { 
          (authUser?.aliquot_brl || userAmounts.brl > 0) && process.env.REACT_APP_OFFICE_ID !== '2'
            ? <Col sm={12} lg={12} xxl={4}>
                <RadioInput name="type" value="BRL" id="type-r-1" label={i._('currency.real')}
                    selected={formState.type === 'BRL'} 
                    description="R$"
                    border
                    onChange={onInputChange} />
              </Col>
            : null
        }
        {
          authUser?.aliquot_usd || userAmounts.usd > 0 
            ? <Col sm={12} lg={12} xxl={4}>
                <RadioInput name="type" value="USD" id="type-r-2" label={i._('currency.dollar')}
                    selected={formState.type === 'USD'} 
                    description="U$"
                    border
                    onChange={onInputChange} />
              </Col>
            : null
        }
        {
          (authUser?.aliquot_btc || userAmounts.btc > 0) && process.env.REACT_APP_OFFICE_ID !== '2'
            ? <Col sm={12} lg={12} xxl={4}>
                <RadioInput name="type" value="BTC" id="type-r-3" label={i._('currency.bitcoin')} 
                    description="BTC"
                    border
                    selected={formState.type === 'BTC'} 
                    onChange={onInputChange} />
              </Col>
            : null
        }
        {
          authUser?.aliquot_usdt || userAmounts.usdt > 0 
            ? <Col sm={12} lg={12} xxl={4}>
                <RadioInput name="type" value="USDT" id="type-r-4" label={i._('currency.tether')} 
                    description="USDT"
                    border
                    selected={formState.type === 'USDT'} 
                    onChange={onInputChange} />
              </Col>
            : null
        }
      </Row>
      <Row className="my-3">
        <Col md={12}>
          <CashoutAvailableAmount type={formState.type} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12}>
          <TextInput type="text" name="value" mask={"currency:" + formState.type} placeholder={i._('cashout.ph_value')}
            label={i._('deposit.value_in', { c: CurrencyUtils.getCurrencySymbol(formState.type)}) } 
            value={formState.value} onChange={onInputChange} error={errors.value} />
        </Col>
      </Row>
      <TaxEstimate value={formState.value} type={formState.type} />
      <Alert {...alert} translate close />
      <Row className="mt-2">
        <Col md={12}>
          <Button type="submit" title={i._('cashout.create')} disabled={disabledButton} loading={loading} color="primary" size="lg" block />
        </Col>
        {
          disabledButton
            ? <Col md={12} className="text-danger mt-2 text-center">
                <FormattedMessage id="permissions.cashout" />
              </Col>
            : null
        }
      </Row>
      {
        dialog
          ? <Dialog 
              onConfirm={lastCashoutID ? onVisualize : onConfirm} 
              onClose={onCancel} 
              title={lastCashoutID ? i._('global.success') : i._('global.confirm')}
              message={lastCashoutID 
                ? i._('cashout.success') 
                : i._('cashout.confirm', { v: `<strong>${CurrencyUtils.getCurrencySymbol(formState.type)} ${formState.value}</strong> `})
              }
              type={lastCashoutID ? "success" : "warning"}
              successButtonText={lastCashoutID ? i._('global.details') : null}
              cancelButtonText={i._('global.close')}
            />
          : null
      }
      
    </form>
  );
}

export default CashoutForm;
