import Card from "common/components/ui/Card"
import FilePreview from "common/components/ui/FilePreview";
import LoadingSpinner from "common/components/ui/LoadingSpinner";
import React from "react"
import { useCurrentCashout } from "reducers/clientReducer"
import useTranslation from "hooks/useTranslation";

const CashoutReceipts = () => {
  const cashout = useCurrentCashout();
  const i = useTranslation();
  
  return (
    <Card fullMobile title={i._('cashout.receipts')} className="text-center">
      <div className="my-3">
        {
          cashout.id
            ? <div>
                { 
                  cashout.receipts.length > 0
                    ? cashout.receipts.map( (receipt, key) => <FilePreview key={"receipt-" + key} fileType="RECEIT" {...receipt} />)
                    : i._('cashout.no_receipts')
                }
              </div>
            : <LoadingSpinner />
        }
      </div>
    </Card>
  )
}

export default CashoutReceipts;