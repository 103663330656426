import { Cashout } from "types/Deposit";
import { Reducer } from "redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

export interface CashoutsState {
  form: boolean,
  list: any[],
  current: Cashout,
}

export enum CashoutTypes {
  TOGGLE_FORM    = '@@client/TOGGLE_FORM',
  UPDATE_LIST    = '@@client/UPDATE_LIST',
  ADD_LIST       = '@@client/ADD_LIST',
  UPDATE_CURRENT = '@@client/UPDATE_CURRENT'
}

const INITITAL_STATE: CashoutsState = {
  form: false,
  list: [],
  current: {
    receipts: []
  }
}

export const cashoutsReducer: Reducer<CashoutsState> = (state = INITITAL_STATE, action) => {
  switch ( action.type ) {
    case CashoutTypes.UPDATE_CURRENT:
        return {
          ...state,
          current: action.payload
        };
    case CashoutTypes.ADD_LIST:
      return {
        ...state,
        cashouts: [action.payload, ...state.list],
        form: false
      };
    case CashoutTypes.TOGGLE_FORM:
      return {
        ...state,
        form: !state.form
      };
    default:
      return state;
  }
}

export const useCurrentCashout = () => useSelector((state: ApplicationState) => state.client.cashout);