import { messages } from "index";
import { createIntl, createIntlCache } from "react-intl";
import AppStorage from "./AppStorage";

export default class IntlUtils {

  private cache;

  private intance;

  constructor(config: any) {
    this.cache = createIntlCache();
    this.intance = createIntl(config, this.cache);
  }

  _(id: string, values?: {}) {
    return this.intance.formatMessage({ id }, values);
  }

  changeLocale(locale: string) {
    this.intance.locale = locale;
    this.intance.messages = messages[locale];
  }

  static isValidLocale(locale: string) {
    return ['en', 'pt', 'ar'].indexOf(locale) !== -1;
  }

  static getCurrentLang() {
    const browserLang  = navigator.language.split(/[-_]/)[0];
    let lang;

    if ( IntlUtils.isValidLocale(browserLang) ) {
      lang = AppStorage.get('locale') || browserLang;
    } else {
      lang = AppStorage.get('locale') || 'en';
    }

    return lang;
  }

  static flattenMessages(nestedMessages: any, prefix = '') {
    return Object.keys(nestedMessages).reduce((messages: any, key: string) => {
      let value = nestedMessages[key]
      let prefixedKey: string = prefix ? `${prefix}.${key}` : key
      if (typeof value === 'string') {
        messages[prefixedKey] = value
      } else {
        Object.assign(messages, IntlUtils.flattenMessages(value, prefixedKey))
      }
      return messages
    }, {});
  }
}