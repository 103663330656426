import { messages } from "index";
import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import { ApplicationState } from "store";

type Props = {
  children: any
}

const Translation: React.FC<Props> = ({ children }) => {
  
  const locale = useSelector((state: ApplicationState) => state.navigation.locale);
  const dispatch = useDispatch();

  useEffect(() => {
    if ( typeof window.location.search === 'string' ) {
      if ( window.location.search.indexOf('lang=en') !== -1 ) {
        dispatch({ type: NavigationTypes.UPDATE_LOCALE, payload: 'en' })
      } 
    }
    // eslint-disable-next-line
  }, []);

  return (
    <IntlProvider locale={locale} messages={messages[locale]} >
      { children }
    </IntlProvider>
  )
}

export default Translation;