import { Col, Row } from 'common/components/ui/Layout'
import {  TextInput, Button } from 'common/components/ui/Form';
import { useDispatch, useSelector } from 'react-redux';
import { ClientTypes } from 'reducers/clientReducer';
import { FormEvent, SyntheticEvent, useState } from 'react';
import CurrencyUtils from 'utils/CurrencyUtils';
import DepositRequests from 'api/DepositRequests';
import useValidator from 'hooks/useValidation';
import { ApplicationState } from 'store';
import { FormattedMessage } from 'react-intl';
import { UserTypes } from 'reducers/userReducer';
import { useEffect } from 'react';
import useTranslation from 'hooks/useTranslation';
import AmountService from 'services/AmountService';
import Dialog from 'common/components/ui/Dialog';
import { useHistory } from 'react-router';
import Alert from 'common/components/ui/Alert';
import { NavigationTypes, useCurrentFund } from 'reducers/navigationReducer';
import InvitationModal from './InvitationModal';
import FundRequests from 'api/FundRequests';
import { getDecodedJWT } from 'utils';
import Latam from './Latam/Latam';
import DepositCurrencyOptions from './DepositCurrencyOptions';

const INITIAL_FORM_STATE = {
  value: '',
  type: 'BRL'
};

const INITIAL_FORM_VALIDATION = {
  value: ['required', 'gte:50']
};

const BITCOIN_FORM_VALIDATION = {
  value: ['required', 'gte:1']
}

export const DepositForm = () => {
  const i = useTranslation();
  const dispatch                           = useDispatch();
  const [invitation, setInvitation]        = useState(false);  // show invitation modal
  const history                            = useHistory();
  const authUser                           = useSelector((state: ApplicationState) => state.user.authUser);
  const focusMode                          = useSelector((state: ApplicationState) => state.navigation.focusMode);
  const [lastDepositID, setLastDepositID]  = useState(null);
  const [loading, setLoading]              = useState(false);
  const [dialog, setDialog]                = useState(false);
  const [masterAccess, setMasterAccess]    = useState(false);
  const [formState, setFormState]          = useState(INITIAL_FORM_STATE);
  const [usdTaxable, setUsdTaxable]        = useState(0);
  const { errors, validate, updateConfig } = useValidator(INITIAL_FORM_VALIDATION);
  const currentFund                        = useCurrentFund();

  const resetForm = (type = 'BRL') => {
    setFormState({ ...INITIAL_FORM_STATE, type });
    updateConfig(INITIAL_FORM_VALIDATION);
  }

  useEffect(() => {
    if (authUser) {
      const currency = CurrencyUtils.getFirstCurrency(authUser);

      getDecodedJWT().then((result: any) => {
        if (typeof result.frc !== 'undefined' && result.frc === 0) {
          setMasterAccess(true);
        }
      })

      
      setFormState({ ...formState, type: currency });
      dispatch({ type: UserTypes.UPDATE_FORM_CURRENCY, payload: currency })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);
  
  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    if (name === 'type') {
      dispatch({ type: UserTypes.UPDATE_FORM_CURRENCY, payload: value === 'USDPIX' ? 'USD' : value });
      updateConfig(value === 'BTC' ? BITCOIN_FORM_VALIDATION : INITIAL_FORM_VALIDATION);
      setFormState({ value: '', type: value });
    } else {
      if ( (formState.type === 'USD') && name === 'value' )  {
        let val = parseFloat(value.replace(/,/g, ''));

        if ( !Number.isNaN(val) ) {
          setUsdTaxable(val - (val * 0.01))
        }
      }

      setFormState({ ...formState, [name]: value });
    }
  }

  const onVisualize = () => {
    dispatch({ type: ClientTypes.UPDATE_DEPOSIT_FORM, payload: false });
    history.push(`/deposit/${lastDepositID}`);
  }

  const onConfirm = async () => {
    setLoading(true);
    setDialog(false);

    const formData = {
      ...formState,
      value: formState.type === 'USD' || formState.type === 'USDT'
        ? CurrencyUtils.usdToReal(formState.value) 
        : formState.value
    }


    try {
      const { data } = await DepositRequests.create(formData);
      setLastDepositID(data.id);
      dispatch({ type: ClientTypes.ADD_DEPOSIT, payload: data });
      AmountService(dispatch).refresh(authUser.id);
      resetForm();
      setLoading(false);
      setDialog(true);
    } catch ( error: any ) {
      setLoading(false);
      if ( error.status === 403 || error.status === 400 ) {
        if ( error.data.message === 'BLOCKED' ) {
          await FundRequests.request();
          setInvitation(true);  
        } else {
          dispatch({
            type: NavigationTypes.UPDATE_ALERT,
            payload: {
              message: error.data.message,
              color: 'danger'
            }
          });
        }

      }
    }
    
  }

  /**
   * Submit
   * @param e 
   */
  const onSubmit = async (e: SyntheticEvent|null = null) => {
    setLastDepositID(null);
    if ( e ) e.preventDefault();
    const formData = {
      ...formState,
      value: formState.type === 'USD' || formState.type === 'USDT' 
        ? CurrencyUtils.usdToReal(formState.value) 
        : formState.value
    }

    setLoading(true);

    try {
      //await FundRequests.request();
      await validate({ ...formData, value: CurrencyUtils.toNumber(formState.value, formState.type) });
      setDialog(true);
    } catch ( err ) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onCloseInvitation = () => {
    dispatch({ type: ClientTypes.UPDATE_DEPOSIT_FORM, payload: false });
    setInvitation(false);
    setLoading(false);
  }

  return (
    <>
      {
        <form onSubmit={onSubmit} className="deposit-form">
          
          {
            !focusMode
              ? <>
                  <Row>
                    <Col sm={12}>
                      <p>
                        <FormattedMessage id="deposit.choose_currency" />
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <DepositCurrencyOptions onSelected={onInputChange} value={formState.type} masterAccess={masterAccess} />
                  </Row>
                </>
              : null
          }
          {
            formState.type !== 'USDPIX'
              ? <Row className="mt-2 mb-3">
                  <Col sm={12}>
                    {
                      formState.type === 'BRL'
                        ? <TextInput
                          type="text" name="value"
                          mask="currency:BRL" placeholder={i._('deposit.ph_deposit')}
                          label={i._('deposit.value_in', { c: 'R$' })} error={errors.value}
                          value={formState.value} onChange={onInputChange} />
                        : null
                    }
                    {
                      formState.type === 'USD' && (currentFund.id !== 1 || masterAccess)
                        ? <TextInput
                          type="text" name="value" autoComplete='off'
                          mask="currency:USD" placeholder={i._('deposit.ph_deposit')}
                          label={i._('deposit.value_in', { c: 'U$' })} error={errors.value}
                          value={formState.value} onChange={onInputChange} />
                        : null
                    }
                    {
                      formState.type === 'USDT'
                        ? <TextInput
                          type="text" name="value" autoComplete='off'
                          mask="currency:USDT" placeholder={i._('deposit.ph_deposit')}
                          label={i._('deposit.value_in', { c: 'USDT' })} error={errors.value}
                          value={formState.value} onChange={onInputChange} />
                        : null
                    }
                    {
                      formState.type === 'BTC' && currentFund.id !== 1
                        ? <TextInput
                          type="text" name="value"
                          mask="currency:BTC" placeholder={i._('deposit.ph_deposit')}
                          label={i._('deposit.value_in', { c: 'BTC' })} error={errors.value}
                          value={formState.value} onChange={onInputChange} />
                        : null
                    }
                    {
                      (formState.type === 'USD') && authUser.taxable_usd && formState.value !== '' && formState.value.length > 3
                        ? <small><FormattedMessage id="deposit.usd_fee" />
                            <strong style={{ fontSize: '1.15em'}} className="text-success"> { CurrencyUtils.format(usdTaxable, 'USD') }</strong>
                          </small>
                        : null
                    }
                  </Col>
                </Row>
              : null
          }
          {
            formState.type === 'USDPIX'
              ? <Latam onReset={() => resetForm('USD')} />
              : null
          }
          
          <Alert translate className='mb-3' close />
          {
            !invitation && formState.type !== 'USDPIX'
              ? <Row>
                  <Col md={12}>
                    <Button type="submit" title={i._('deposit.create_deposit')} color="primary" loading={loading} className="save-button" size="lg" block />
                  </Col>
                </Row>
              : null
          }
        </form>
      }
      {
        invitation
          ? <InvitationModal onClose={onCloseInvitation} />
          : null
      }
      {
        dialog
          ? <Dialog 
              title={lastDepositID ? i._('global.success') : i._('global.confirm')}
              message={lastDepositID 
                ? i._('deposit.created') 
                : (formState.type === 'USD') && authUser.taxable_usd
                  ? i._('deposit.confirm', { v: `<strong> ${ CurrencyUtils.format(usdTaxable, formState.type) }</strong> ` })
                  : i._('deposit.confirm', { v: `<strong>${CurrencyUtils.getCurrencySymbol(formState.type)} ${ formState.value }</strong> ` })
              }
              type={lastDepositID ? 'success' : 'warning'}
              onConfirm={lastDepositID ? onVisualize : onConfirm}
              successButtonText={lastDepositID ? i._('global.details') : null}
              cancelButtonText={i._('global.close')}
              onClose={() => setDialog(false)} 
              />
          : null
      }
      
    </>
  );
}

export default DepositForm;

