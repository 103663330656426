import React from "react";

type Props = {
  customSVGStyle?: React.CSSProperties,
  className?: string,
  size?: number,
  animate?: boolean,
  text?: boolean
  fontSize?: number,
  gradientAmount?: number,
  textScale?: number,
  color?: boolean
}

const ArbLogo: React.FC<Props> = ({ size = 60, className = '', color = true, text = true }) => {

  const PCOLOR = color ? '#2C373F' : '#FFFFFF';
  const ACOLOR = color ? '#DBBD89' : '#FFFFFF';

  return (
    <div className={"root-logo " + className}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 3540.5 1017.2" style={{ height: size }}>
        <g>
          {
            text
              ? <g>
              <path fill={PCOLOR} d="M2379.2,403.1h-78.4V632h-56.7V403.1h-79.2v-56.7h214.2V403.1z" />
              <path fill={PCOLOR} d="M2885.2,634.9c-16.3,0-31.3-2.8-44.9-8.4c-13.6-5.6-25.2-13.3-34.9-23.1c-9.7-9.8-17.2-21.5-22.6-35.1
        c-5.4-13.6-8.2-28.6-8.2-44.9V346.4h56.3v169.8c0,9.5,1.5,18.2,4.5,25.9c3,7.8,7,14.3,12,19.6c5,5.3,10.8,9.4,17.3,12.2
        s13.3,4.3,20.4,4.3c7.1,0,13.9-1.4,20.4-4.3s12.3-6.9,17.3-12.2c5-5.3,9-11.8,12-19.6c3-7.8,4.5-16.4,4.5-25.9V346.4h56.7v177.1
        c0,16.3-2.7,31.3-8.2,44.9c-5.4,13.6-13.1,25.3-22.9,35.1c-9.8,9.8-21.5,17.5-35.1,23.1C2916.4,632.1,2901.5,634.9,2885.2,634.9z"
              />
              <path fill={PCOLOR} d="M3172.5,634.9c-12.5,0-24.6-1.8-36.3-5.3c-11.7-3.5-22.7-8.6-33.1-15.1h0.4c-7.3-4.6-13.9-9.7-19.6-15.1
        c-5.7-5.4-9.7-9.4-11.8-11.8l42.4-39.2c1.9,2.4,5,5.4,9.2,8.8c4.2,3.4,9,6.7,14.5,9.8c5.4,3.1,11.2,5.8,17.1,8
        c6,2.2,11.7,3.3,17.1,3.3c4.6,0,9.2-0.5,13.9-1.6c4.6-1.1,8.8-2.8,12.7-5.1c3.8-2.3,6.9-5.4,9.4-9.2c2.4-3.8,3.7-8.6,3.7-14.3
        c0-2.2-0.8-4.6-2.2-7.1c-1.5-2.6-4.1-5.4-7.8-8.4c-3.7-3-8.8-6.2-15.3-9.6c-6.5-3.4-14.7-7-24.5-10.8c-11.4-4.3-22-9-31.8-14.1
        c-9.8-5-18.3-10.9-25.5-17.8c-7.2-6.8-12.8-14.6-16.7-23.3c-3.9-8.7-5.9-18.8-5.9-30.2c0-11.7,2.2-22.6,6.5-32.9
        c4.3-10.2,10.5-19,18.6-26.5c8-7.5,17.7-13.3,29-17.5c11.3-4.2,23.7-6.3,37.3-6.3c7.3,0,15.2,1,23.5,2.9
        c8.3,1.9,16.4,4.4,24.3,7.3c7.9,3,15.3,6.5,22.2,10.4c6.9,3.9,12.7,8,17.3,12l-35.1,44.1c-5.2-3.5-10.5-6.8-15.9-9.8
        c-4.6-2.4-9.9-4.8-15.9-6.9c-6-2.2-12.1-3.3-18.4-3.3c-11.4,0-20.3,2.2-26.7,6.7c-6.4,4.5-9.6,11-9.6,19.4c0,3.8,1.2,7.3,3.5,10.4
        c2.3,3.1,5.4,6,9.4,8.6c3.9,2.6,8.5,5,13.7,7.1c5.2,2.2,10.6,4.2,16.3,6.1c16.3,6.3,30.1,12.6,41.2,19
        c11.2,6.4,20.1,13.2,26.9,20.4c6.8,7.2,11.6,15,14.5,23.3c2.9,8.3,4.3,17.3,4.3,27.1c0,12-2.4,23.3-7.3,33.9
        c-4.9,10.6-11.6,19.8-20.2,27.5c-8.6,7.8-18.8,13.9-30.6,18.4C3199.2,632.6,3186.3,634.9,3172.5,634.9z"/>
              <path fill={PCOLOR} d="M3540.5,403.1h-78.4V632h-56.7V403.1h-79.2v-56.7h214.2V403.1z" />
              <g>
                <g>
                  <path fill={PCOLOR} d="M1283.5,632c3.3-7.9,7-16.5,11.2-25.9c4.2-9.4,8.1-18.2,11.6-26.3h0.2l25-56.7h-0.3l29.4-66.1h0l0,0
            l77.9,175.1h62.4l-128.5-288.5h-24.1L1221.4,632L1283.5,632L1283.5,632z"/>
                </g>
              </g>
              <g>
                <g>
                  <path fill={PCOLOR} d="M1726.6,514.5c15-6.8,27.1-17.3,36.3-31.6c9.2-14.3,13.9-30.4,13.9-48.4c0-12.2-2.4-23.7-7.1-34.3
            c-4.8-10.6-11.2-19.9-19.2-28c-8-8-17.3-14.3-28-19c-10.6-4.6-21.9-6.9-33.9-6.9h-115.5V632h56.3v-87.5h-1.1v-87.5h1.1v-53.9
            h48.6c14.4,0,25,3.1,31.8,9.2c6.8,6.1,10.2,13.5,10.2,22.2c0,8.7-3.4,16.3-10.2,22.6c-6.8,6.4-17.4,9.6-31.8,9.6h-13.7v57.1
            l77,108.2h69.4L1726.6,514.5z"/>
                </g>
              </g>
              <g>
                <g>
                  <path fill={PCOLOR} d="M2617.1,514.5c15-6.8,27.1-17.3,36.3-31.6c9.2-14.3,13.9-30.4,13.9-48.4c0-12.2-2.4-23.7-7.1-34.3
            c-4.8-10.6-11.2-19.9-19.2-28c-8-8-17.3-14.3-28-19c-10.6-4.6-21.9-6.9-33.9-6.9h-115.5V632h56.3v-87.5h-0.1v-87.5h0.1v-53.9
            h48.6c14.4,0,25,3.1,31.8,9.2c6.8,6.1,10.2,13.5,10.2,22.2c0,8.7-3.4,16.3-10.2,22.6c-6.8,6.4-17.4,9.6-31.8,9.6h-12.7v58.5
            l76,106.8h69.4L2617.1,514.5z"/>
                </g>
              </g>
              <g>
                <g>
                  <path fill={PCOLOR} d="M2097.6,528.4c-1.6-6-4.1-11.8-7.3-17.3c-3.3-5.6-7.4-10.8-12.6-15.7c-5.1-4.9-11.2-9-18.3-12.2
            c11.6-8.2,19.5-17.6,23.7-28.4c4.2-10.7,6.3-20.7,6.3-30c0-10.1-2.2-19.9-6.7-29.4c-4.5-9.5-10.7-17.9-18.5-25.1
            c-7.9-7.2-17.1-13-27.7-17.3c-10.6-4.3-22-6.5-34.2-6.5h-114.4V632h115.7c18.5,0,33.9-2.5,46.2-7.5c12.4-5,22.3-11.6,29.7-19.8
            c7.5-8.2,12.8-17.3,15.9-27.5c3.1-10.2,4.7-20.6,4.7-31.2C2100,540.2,2099.2,534.3,2097.6,528.4z M2029.8,566.5
            c-6.8,5.9-18,8.8-33.5,8.8h-51.8v-30.8h-0.1V420.6h0.1v-17.5h51.4c10.1,0,18.2,2.8,24.3,8.4c6.1,5.6,9.2,12.3,9.2,20.2
            c0,7.9-3.1,14.2-9.2,19c-6.1,4.8-14.2,7.1-24.3,7.1h-10.4v56.7h10.8c8.4,0,15.5,0.9,21.2,2.7c5.7,1.8,10.2,4.1,13.5,6.9
            c3.3,2.9,5.6,6.2,6.9,10c1.4,3.8,2,7.8,2,11.8C2040,553.8,2036.6,560.7,2029.8,566.5z"/>
                </g>
              </g>
            </g>
              : null
          }
          <g>
            <path fill={ACOLOR} d="M671,439.5V267.8H566.6c-176.6,0-319.8,143.2-319.8,319.8v161.8h0c57,0,103.3-46.2,103.3-103.3v-59.6
			c0-118.5,96.1-214.6,214.6-214.6h9.2V438L671,439.5z"/>
            <path fill={PCOLOR} d="M477,577.3v-97.6h293.4v97.6h-96.9v71.8c0,55.4-44.9,100.3-100.3,100.3h0V576.6L477,577.3z" />
            <g>
              <path fill={PCOLOR} d="M508.6,1017.2c-68.6,0-135.3-13.5-198-40c-60.6-25.6-115-62.3-161.7-109c-46.7-46.7-83.4-101.1-109-161.6
				c-26.5-62.7-40-129.3-40-198c0-68.6,13.5-135.3,40-198c25.6-60.6,62.3-115,109-161.6C195.7,102.3,250,65.6,310.6,40
				c62.7-26.5,129.3-40,198-40s135.3,13.5,198,40c60.6,25.6,115,62.3,161.7,109s83.4,101.1,109,161.6c26.5,62.7,40,129.3,40,198
				c0,68.6-13.5,135.3-40,198c-25.6,60.6-62.3,115-109,161.6s-101.1,83.4-161.7,109C643.8,1003.7,577.2,1017.2,508.6,1017.2z
				 M508.6,60C448,60,389.3,71.9,334,95.2c-53.4,22.6-101.4,54.9-142.6,96.1S117.8,280.6,95.2,334C71.9,389.3,60,448,60,508.6
				s11.9,119.3,35.2,174.6c22.6,53.4,54.9,101.4,96.1,142.6c41.2,41.2,89.2,73.6,142.6,96.1c55.3,23.4,114,35.2,174.6,35.2
				c60.6,0,119.3-11.9,174.6-35.2c53.4-22.6,101.4-54.9,142.6-96.1c41.2-41.2,73.6-89.2,96.1-142.6c23.4-55.3,35.2-114,35.2-174.6
				S945.3,389.3,921.9,334c-22.6-53.4-54.9-101.4-96.1-142.6c-41.2-41.2-89.2-73.6-142.6-96.1C627.9,71.9,569.2,60,508.6,60z"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );

}

export default ArbLogo;