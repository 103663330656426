import { api, getRequestOptions } from "api";

export default class CashoutRequests {

    static list(page: number) {
        return api.get('/cashouts?page=' + page, getRequestOptions({ skipErrors: [404] }));
    }

    static get(id: number) {
        return api.get('/cashouts/' + id, getRequestOptions());
    }

    static create(formData: any) {
        return api.post('/cashouts', formData, getRequestOptions());
    }

    static estimate(value: number, type: string) {
        return api.post('/cashouts/simulate', { value, type }, getRequestOptions());
    }

    static penalty(value: number) {
        return api.post('/cashouts/penalty', { value }, getRequestOptions({ skipErrors: [404] }));   
    }

    static penaltyAlert() {
        return api.get('/cashouts/penalty/alert', getRequestOptions());
    }
    
}