import ClientRequest from "api/ClientRequest";
import { UserTypes } from "reducers/userReducer";
import { getCurrentFundClient } from "utils/AuthUtils";

function AmountService(dispatch: any) {

  // refreshes user amount
  const refresh = async (client_id: number) => {
    try {
      const { data } = await ClientRequest.amounts(getCurrentFundClient());
      dispatch({
        type: UserTypes.UPDATE_AUTH_USER_AMOUNTS,
        payload: [data]
      });
    } catch ( error: any ) {
      
    }
  }

  return {
    refresh
  }

}

export default AmountService;