import { FirstLogo, LiquidezLogo, PerformanceLogo } from "common/components/logos";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Colors } from "types/config";
import { AppStorage, CurrencyUtils } from "utils";

type TFeedTypes = 'cashout'|'reinvest'|'payment'|'deposit';

export type TFeed = {
  value: number,
  type: TFeedTypes,
  status: number,
  created_at: string,
  currency: 'BTC'|'USD'|'BRL'
  fund_id: number,
  fund_name: string
}

const typeNameResolver = (type: TFeedTypes) => {
  if ( type === 'cashout' ) return 'global.cashout'
  if ( type === 'deposit' ) return 'global.deposit'
  if ( type === 'payment' ) return 'bank.payment_type'
  if ( type === 'reinvest' ) return 'deposit.reaport'
}

const StatusResolver: React.FC<{ type: TFeedTypes, status: number }> = ({ type, status }) => {
  if ( type === 'deposit' || type === 'cashout' || type === 'reinvest' ) {
    return (
      <div className={"d-flex align-items-center " + (status === 1 ? 'text-success' : 'text-warning')}>
        <strong>
          <small>
            <FormattedMessage id={ status === 1 ? 'status.confirm' : 'status.pending' } />
          </small>
        </strong>
      </div>
    );
  } else { 
    return (
      <div className="d-flex text-success align-items-center">
        <strong>
          <small>
            <FormattedMessage id={ status === 1 ? 'status.confirm' : 'status.confirm' } />
          </small>
        </strong>
      </div>
    );
  }
}

const FeedItem: React.FC<TFeed> = ({ value, type, status, created_at, fund_name, fund_id, currency }) => {

  const valueColor = type === 'cashout' ? Colors.ERROR : Colors.SUCCESS
  const createdAt = created_at ? new Date(created_at.replace(/-/g, "/")) : new Date();

  return (
    <div className="FeedItem d-flex flex-column">
      <div className="feed-header d-flex justify-content-between">
        <div className="item-type">
          <h5>
            <FormattedMessage id={typeNameResolver(type)} />
          </h5>
        </div>
        <div className="d-flex flex-column align-items-end">
          <div className="d-flex">
            <div className="item-icon mx-2">
              { fund_id === 1 ? <FirstLogo color={Colors.PRIMARY_LIGHT} size={22} /> : null }
              { fund_id === 2 ? <LiquidezLogo color={Colors.PRIMARY_LIGHT} size={22} /> : null }
              { fund_id === 3 ? <PerformanceLogo color={Colors.PRIMARY_LIGHT} size={22} /> : null }
            </div>
            <strong>
              <small>{ fund_name }</small>
            </strong>
          </div>
        </div>
      </div>
      <div className="d-flex feed-value justify-content-between">
        <div className="item-value" style={{ color: valueColor }}>
            { CurrencyUtils.format(value, currency)}
        </div>
        <StatusResolver type={type} status={status} />
      </div>
      <div className="feed-date d-flex feed-content">
        <small>{createdAt.toLocaleDateString(AppStorage.get('locale') || undefined)}</small>
      </div>

      {/* <div className="item-status"></div> */}
    </div>
  )
}

export default FeedItem;