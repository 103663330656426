import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { ClientTypes } from 'reducers/clientReducer';
import { ApplicationState } from 'store';
import { UserTypes } from '../../reducers/userReducer';
import AppStorage from '../../utils/AppStorage';

const Logout = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((state: ApplicationState) => state.user.authUser);
  const i = useTranslation();

  AppStorage.delete('token');
  AppStorage.delete('val');
  AppStorage.delete('fcl');
  
  dispatch({
    type: UserTypes.LOGOUT,
    payload: {
      message: i._('auth.success_logout'),
      color: 'success'
    }
  });

  dispatch({ type: ClientTypes.RESET });
  dispatch({ type: UserTypes.RESET });

  if ( !authUser ) {
    return <Redirect to="/auth/login" />
  } else {
    return <>oo</>;
  }
}

export default Logout;