import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import IntlUtils from 'utils/IntlUtils';
import messages_pt from "./translations/pt.json";
import messages_en from "./translations/en.json";
import messages_ar from "./translations/ar.json";

const lang = IntlUtils.getCurrentLang();

export const messages: any = {
  pt: IntlUtils.flattenMessages(messages_pt),
  en: IntlUtils.flattenMessages(messages_en),
  ar: IntlUtils.flattenMessages(messages_ar)
}

export const i = new IntlUtils({ locale: lang, messages: messages[lang] });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
