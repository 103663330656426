import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useHistory } from 'react-router';
import { NavigationTypes } from 'reducers/navigationReducer';
import ReactGA from 'react-ga';
import { useTranslation } from 'hooks';
import { MAINTENANCE_MODE } from 'types/config';
import ArbLogo from './logos/ArbLogo';
import { SbarainiLogo } from './logos';
import { FormattedMessage } from 'react-intl';

type Props = { 
  path?: string,
  exact?: boolean,
  component: any,
  guard?: boolean
};

const MaintenanceAlert = () => (
  <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
    {
      process.env.REACT_APP_OFFICE_ID === '2'
        ? <ArbLogo size={33} />
        : <SbarainiLogo size={50} />
    }

    <h2 className='mt-4'>
      <FormattedMessage id="maintenance.title" />
    </h2>

    <p className='text-center'>
      <FormattedMessage id="maintenance.description" />
    </p>
  </div>
)

const AppRoute: React.FC<Props> = ({ path, exact, component, guard }) => {
  const dispatch = useDispatch();
  const history  = useHistory();
  const { locale } = useTranslation();

  useEffect(() => {
    dispatch({ type: NavigationTypes.PAGE_CHANGE, payload: history.location.pathname });
    history.listen((location, action) => {

      ReactGA.pageview(window.location.pathname + window.location.search);

      dispatch({ type: NavigationTypes.PAGE_CHANGE, payload: location.pathname });
      dispatch({ type: NavigationTypes.CLEAR_API_ERROR });
      dispatch({ type: NavigationTypes.TOGGLE_FOCUS_MODE, payload: false });

      window.onbeforeunload = null;
      document.body.scrollTop = 0; 
      document.documentElement.scrollTop = 0; 
      
    });
  }, [dispatch, history]);

  useEffect(() => {
    if ( locale === 'ar' ) {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    } else {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }
  }, [locale]);

  
  return MAINTENANCE_MODE
    ? <MaintenanceAlert />
    : <Route path={path} exact={exact} component={component} />;  
}

export default AppRoute;