import React from "react";
import { useRef } from "react";
import { Transition } from "react-transition-group";

type GeneralProps = {
  show: any,
  timeout: number,
  children?: any,
  style?: any
}

const fadeLeftCss: any = {
  entering: { opacity: 0, marginLeft: -20, display: 'block' },
  entered: { opacity: 1, marginLeft: 0, display: 'block' },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const fadeDownCss: any = {
  entering: { opacity: 0, marginBottom: -20, display: 'block' },
  entered: { opacity: 1, marginBottom: 0, display: 'block' },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

export const AnimateFadeLeft: React.FC<GeneralProps> = ({ timeout, show, children, style }) => {
  const animationRef = useRef();

  return (
    <Transition in={show} nodeRef={animationRef} timeout={timeout}>
      {
        state => React.cloneElement(children, { 
            style: {
              ...style,
              transition: `all ease-in-out ${timeout}s`,
              display: 'none',
              marginLeft: -20,
              ...fadeLeftCss[state]
            }
          })
      }
    </Transition>
  )
}

export const AnimateFadeDown: React.FC<GeneralProps> = ({ timeout, show, children, style }) => {
  const animationRef = useRef();

  return (
    <Transition in={show} nodeRef={animationRef} timeout={timeout}>
      {
        state => React.cloneElement(children, { 
            style: {
              ...style,
              transition: `all ease-in-out ${timeout}s`,
              display: 'none',
              marginBottom: -20,
              ...fadeDownCss[state]
            }
          })
      }
    </Transition>
  )
}


