import React from 'react';
import CopyContent from 'common/components/CopyContent';
import { SbarainiLogo } from 'common/components/logos';
import Card from 'common/components/ui/Card';
import { Col, Row } from 'common/components/ui/Layout';
import { useTranslation } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'reducers/userReducer';
import ArbLogo from 'common/components/logos/ArbLogo';

const DefaultMessage = () => {
  const authUser = useAuth();
  const foreign = authUser?.personal?.country !== 'BR';

  const phone = foreign ? '+55 41 3013-8207' : '(41) 3013-8207';
  const email = process.env.REACT_APP_OFFICE_ID !== '1' ? 'arbtrust@arbtrustus.com' : 'fiscal@sbenx.com.br';

  return (
    <>
      <FormattedMessage id="ir.text_1" /><br />
      <br />
      <FormattedMessage id="ir.text_2" /><br /><br />
      <CopyContent before className="fw-bold" text={email} /><br />
      {
        !foreign && false
          ? <>
              <FormattedMessage id="ir.text_5" /><br />
              <CopyContent before className="fw-bold" copyOnlyNumbers text={phone} /><br />     
            </>
          : null
      }
      <br />
      <FormattedMessage id="ir.text_4" /><br />
      <br />
      {
        process.env.REACT_APP_OFFICE_ID === '2'
          ? <ArbLogo size={45} />
          : <SbarainiLogo size={35} />
      }
    </>
  )
}

const TemporaryMessage = () => {
  return (
    <>
      <FormattedMessage id="ir.text_1" /><br /><br />
      Informamos que a entrega das obrigações acessórias a RFB foi finalizada, estamos providenciando o informe de rendimentos e em breve estará disponível neste canal.
    </>
  )
}

const ManualReport = () => {
  const i = useTranslation();

  return (
    <Row>
      <Col sm={12}>
        <Card title={i._('ir.income_tax')} fullMobile border className='mb-2'>
          
          { process.env.REACT_APP_OFFICE_ID !== '2' ? <TemporaryMessage /> : <DefaultMessage/> }
        </Card>
      </Col>
    </Row>
  )
}

export default ManualReport;