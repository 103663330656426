import CashoutRequests from "api/CashoutRequests";
import Card from "common/components/ui/Card";
import useTranslation from "hooks/useTranslation";
import React, { useEffect } from "react";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ClientTypes, useCurrentCashout } from "reducers/clientReducer";
import AppStorage from "utils/AppStorage";
import CurrencyUtils from "utils/CurrencyUtils";
import DepositStatus from "../../Deposit/components/DepositStatus";
import CashoutTransaction from "./CashoutTransaction";

const Loading = ({ ...props }) => (
  <ContentLoader
      speed={2}
      width={props.width || 116}
      height={27}
      viewBox={"0 0 " + (props.width || 116) + " 27"}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
    <rect x="1" y="-1" rx="3" ry="3" width={props.width || 116} height="27" />
  </ContentLoader>
);

const CashoutInformation = () => {
  const i = useTranslation();
  const cashout       = useCurrentCashout();
  const dispatch      = useDispatch();
  const { id }: any   = useParams();
  const createdAt = cashout.created_at ? new Date(cashout.created_at.replace(/-/g, "/")) : null;

  useEffect(() => {
    CashoutRequests.get(id)
      .then( response => dispatch({ type: ClientTypes.UPDATE_CASHOUT, payload: response.data  }))
      .catch( error => console.warn(error))
  }, [id, dispatch]);

  return (
    <>
      <Card title={i._('global.plural.information')} fullMobile>
        <ul className="listview flush transparent simple-listview no-space mt-3">
          <li>
            <strong>Status</strong>
            {
              cashout.status || cashout.status === 0 
                ? <DepositStatus status={cashout.status} /> 
                : <Loading />
            }
          </li>
          <li>
            <strong>
              <FormattedMessage id="global.method" />
            </strong>
            <span>
              {
                createdAt
                  ? <FormattedMessage id="bank.payment_type" />
                  : <Loading width={100} />
              }
              
            </span>
          </li>
          <li>
            <strong>
              <FormattedMessage id="global.date" />
            </strong>
            {
              createdAt
                ? createdAt.toLocaleDateString(AppStorage.get('locale') || undefined)
                : <Loading width={110} />
            }
          </li>
          <li>
            <strong>
              <FormattedMessage id="global.value" />
            </strong>
            {
              cashout.value
                ? <h3 className="m-0">{CurrencyUtils.format(cashout.value, cashout.type)}</h3>
                : <Loading width={130} />
            }
          </li>
        </ul>
      </Card>
      {
        cashout && cashout.transaction
          ? <CashoutTransaction transaction={cashout.transaction} />
          : null
      }
    </>
  )
}

export default CashoutInformation;