import React from 'react';

type RowProps = {
  className?: string;
  children?: any;
}

const Row: React.FC<RowProps> = ({ className, children }) => {

  const classes = ['row'];

  if ( className ) classes.push(className);

  return (
    <div className={classes.join(' ')}>
      { children }
    </div>
  );
}

export default Row;