import { useEffect } from "react"
import { APP_LEGAL_NAME_F } from "types/config";

const useTitle = (title: string, defaultTitle = true) => {
  useEffect(() => {
    let finalTitle = '';
    
    if ( defaultTitle ) {
      finalTitle += APP_LEGAL_NAME_F + ' - ';
    }

    document.title = finalTitle += title;
  }, [title, defaultTitle]);
}

export default useTitle;