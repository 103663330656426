import { api, getRequestOptions } from ".";

export default class FundRequests {
    static request() {
        return api.get('/funds/request', getRequestOptions());
    }

    static requestStatus() {
        return api.get('/funds/request/status', getRequestOptions());
    }

    static amounts() {
        return api.get('/funds/amounts', getRequestOptions());
    }

    static totalAmount() {
        return api.get('/funds/total/amount', getRequestOptions());
    }

    static beta() {
        return api.get('/funds/beta', getRequestOptions());
    }

    static feed(page: number = 0) {
        return api.get('/funds/feed?page=' + page, getRequestOptions({ skipErrors: [500] }));
    }
}