import React, { useEffect, useRef } from "react";
import { Col, Row } from "common/components/ui/Layout";
import LoadingSpinner from "common/components/ui/LoadingSpinner";
import CopyContent from "common/components/CopyContent";
import { QRCodeSVG } from "qrcode.react";
import LatamRequests from "api/LatamRequests";
import { extractData } from "api";

type Props = {
    pixDetails: any,
    onTimeout: () => void,
    onSuccess: (o: any) => void
}

const PIX_TIMEOUT = 5 * 60 * 1000;
const LatamQRCode: React.FC<Props> = ({ pixDetails, onTimeout, onSuccess }) => {

    const timerRef: any = useRef();
    const targetDate = (new Date(pixDetails.created_at)).getTime() + PIX_TIMEOUT;

    
    useEffect(() => {
        const i = setInterval(() => {
            if(timerRef.current){
                const n = (new Date()).getTime();
                const diff = targetDate - n;

                if (diff > 0) {
                    timerRef.current.innerText = Math.ceil(diff / 1000);
                } else {
                    clearInterval(i)
                    onTimeout()
                }
            }
        }, 1000);

        const p = setInterval(() => {
            LatamRequests.get(pixDetails.latam_id)
                .then(extractData)
                .then(response => {
                    if (response.status === 'Pago') {
                        onSuccess(response)
                    }
                })
                .catch(err => console.log(err))
        }, 2500)

        return () => {
            clearInterval(i)
            clearInterval(p)
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    return (
        <>
            <Row>
                <Col md={12} className="my-4">
                    <div className="text-center">
                        <QRCodeSVG size={250} value={pixDetails.pixcode} className="qr-code-wrapper" />
                    </div>
                </Col>
            </Row>
            <Row className="d-flex align-items-center">
                <Col md={12} className="d-flex align-items-center justify-content-between mt-2 mb-2 qr-code-text">
                    <div className="d-flex align-items-center">
                        <LoadingSpinner /> 
                        <div style={{ marginLeft: 10, lineHeight: '130%' }}>
                            <span>Awaiting Payment</span> <br />
                            <small>QR Code valid for <strong ref={timerRef}>300</strong><strong>s</strong>   </small>
                        </div>
                    </div>
                    <CopyContent asButton wrap text={pixDetails.pixcode} customText="Copy PIX Code" />
                </Col>
            </Row>
        </>
    )
}

export default LatamQRCode;