import React from "react";
import { IoReloadSharp } from "react-icons/io5";

type Props = {
  size?: number,
  color?: string
}

const PerformanceLogo: React.FC<Props> = ({ size, color = 'while' }) => {
  return (
    <IoReloadSharp size={size} />
  )
}

export default PerformanceLogo;