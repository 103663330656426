import { ColorsProps } from "common/components/ui/GeneralProps";
import { useSelector } from "react-redux";
import { Reducer } from "redux";
import { ApplicationState } from "store";
import IntlUtils from "utils/IntlUtils";

export interface DialogState {
  type?: string,
  open: boolean,
  title: string,
  description: string,
  successLink: string,
  successButtonText: string,
  successButton: boolean,
  closeButtonText: string
}

export interface ApiError {
  message: string,
  status: number|false,
  goBack: string|null,
}

export interface AlertState extends ColorsProps {
  message?: string,
}

export interface NavigationState {
  sidebar: boolean,
  depositForm: boolean,
  currentPage: string,
  dialog: DialogState,
  showValues: boolean,
  fund: any,
  locale: string,
  toast: boolean|string,
  alert: AlertState | false,
  focusMode: boolean,
  tokenCheck: boolean,
  apiError: ApiError,
  onboardingCountry: 'US'|'BR'
}

export enum NavigationTypes {
  TOGGLE_SIDEBAR      = '@@navigation/TOGGLE_SIDEBAR',
  TOGGLE_DEPOSIT_FORM = '@@navigation/TOGGLE_DEPOSIT_FORM',
  PAGE_CHANGE         = '@@navigation/PAGE_CHANGE',
  TOGGLE_DIALOG       = '@@navigation/TOGGLE_DIALOG',
  UPDATE_DIALOG       = '@@navigation/UPDATE_DIALOG',
  TOGGLE_VALUES       = '@@navigation/TOGGLE_VALUES',
  UPDATE_ALERT        = '@@navigation/UPDATE_ALERT',
  UPDATE_FUND         = '@@navigation/UPDATE_FUND',
  SHOW_TOAST          = '@@navigation/SHOW_TOAST',
  UPDATE_LOCALE       = '@@navigation/UPDATE_LOCALE',
  UPDATE_TOKEN_CHECK  = '@@navigation/UPDATE_TOKEN_CHECK',
  THROW_API_ERROR     = '@@navigation/THROW_API_ERROR',
  TOGGLE_FOCUS_MODE   = '@@navigation/TOGGLE_FOCUS_MODE',
  CLEAR_API_ERROR     = '@@navigation/CLEAR_API_ERROR',
  UPDATE_ONBOARDING_COUNTRY = '@@navigation/UPDATE_ONBOARDING_COUNTRY'
}

const INITIAL_API_ERROR_STATE: ApiError = {
  message: '',
  status: false,
  goBack: null
}

const INITITAL_STATE: NavigationState = {
  sidebar: false,
  depositForm: false,
  currentPage: '',
  showValues: false,
  alert: false,
  fund: null,
  locale: IntlUtils.getCurrentLang(),
  toast: false,
  tokenCheck: false,
  onboardingCountry: 'BR',
  focusMode: false,
  dialog: {
    open: false,
    title: '',
    description: '',
    successButton: false,
    successButtonText: 'OK',
    successLink: '',
    closeButtonText: 'FECHAR'
  },
  apiError: INITIAL_API_ERROR_STATE
}

export const navigationReducer: Reducer<NavigationState> = (state = INITITAL_STATE, action) => {
  switch ( action.type ) {
    case NavigationTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: !state.sidebar
      };
    case NavigationTypes.TOGGLE_DEPOSIT_FORM:
      return {
        ...state,
        depositForm: !state.depositForm
      };
    case NavigationTypes.PAGE_CHANGE:
      return {
        ...state,
        sidebar: false,
        currentPage: action.payload,
        alert: false,
        dialog: {
          ...state.dialog,
          open: false
        }
      };
    case NavigationTypes.UPDATE_DIALOG:
      return {
        ...state,
        dialog: action.payload
      };
    case NavigationTypes.TOGGLE_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          open: !state.dialog.open
        }
      };
    case NavigationTypes.UPDATE_ALERT:
      return {
        ...state,
        alert: action.payload
      };
    case NavigationTypes.TOGGLE_VALUES:
      return {
        ...state,
        showValues: !state.showValues
      };
    case NavigationTypes.SHOW_TOAST:
      return {
        ...state,
        toast: action.payload
      };
    case NavigationTypes.TOGGLE_FOCUS_MODE:
      return {
        ...state,
        focusMode: action.payload
      };
    case NavigationTypes.UPDATE_FUND:
      return {
        ...state,
        fund: action.payload
      };
    case NavigationTypes.UPDATE_LOCALE:
      return {
        ...state,
        locale: action.payload
      };
    case NavigationTypes.UPDATE_TOKEN_CHECK:
      return { 
        ...state, 
        tokenCheck: action.payload 
      };
    case NavigationTypes.THROW_API_ERROR:
      return {
        ...state,
        apiError: action.payload
      };
    case NavigationTypes.CLEAR_API_ERROR:
      return {
        ...state,
        apiError: INITIAL_API_ERROR_STATE
      }
    default:
      return state;
  }
}

export const useConfirm = () => {
  return (
    title: string, 
    description: string, 
    type: string = 'danger', 
    dispatch: any,
    successButtonText: string|null = null,
    closeButtonText: string|null = null,
  ) => {
    const payload: any = {
      open: true,
      type,
      title,
      description,
      successButton: true,
    };

    if ( successButtonText ) payload.successButtonText = successButtonText;
    if ( closeButtonText ) payload.closeButtonText = closeButtonText;

    dispatch({
      type: NavigationTypes.UPDATE_DIALOG,
      payload: payload
    })
  }
}

export const useCurrentFund = () => useSelector((state: ApplicationState) => state.navigation.fund);