import { useIntl } from "react-intl"

const useTranslation = () => {
  const intl = useIntl();

  const _ = (id: string, values: any = {}) => intl.formatMessage({ id }, values);

  return {
    _,
    locale: intl.locale
  }
}

export default useTranslation;