import { Button } from "common/components/ui/Form";
import React from "react";
import { IoRefreshOutline, IoWarningOutline } from "react-icons/io5"

type Props = {
    error: string,
    onReset: () => void
}
const LatamError: React.FC<Props> = ({ error, onReset }) => {
    return <div className="text-center mt-3">
        <IoWarningOutline size={30} className="text-danger" />
        <h5 className="text-center mt-3">
            {error}
        </h5>
        <p className="mt-2">
            The payment link has expired, if you made the payment anyway, please wait a moment to view the deposit. 
        </p>
        <Button onClick={onReset} icon={<IoRefreshOutline size={18} />} color="primary" title="Try again" type="button" className="mt-4" />
    </div>
}

export default LatamError;