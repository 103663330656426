import React, { ChangeEvent, useRef } from "react";

type Props = {
  label?: string,
  id?: string,
  name: string
  value: string,
  selected?: boolean,
  border?: boolean,
  description?: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RadioInput: React.FC<Props> = ({ label, name, id, value, selected, onChange, border, description }) => {

  const ref: any = useRef();

  const triggerClick = () => ref.current.click();

  return (
    <div className={"form-check " + (border ? 'border' : '') + (selected ? ' selected' : '')} onClick={() => border ? triggerClick() : null}>
        <input className="form-check-input" type="radio" name={name} id={id} value={value} checked={selected} 
          onChange={onChange} ref={ref} />
        { 
          label 
            ? <label className="form-check-label" htmlFor={id}>
                { label }
                {
                  description
                    ? <>
                        <br/>
                        <small>{ description }</small>
                      </>
                    : null
                }
              </label>
            : null
        }
    </div>
  );
}

export default RadioInput;