import { AnimateFadeLeft } from "common/components/Animations";
import { FirstLogo, LiquidezLogo, PerformanceLogo } from "common/components/logos";
import { Button } from "common/components/ui/Form";
import { useTranslation } from "hooks";
import React, { useEffect, useState } from "react";
import { IoChevronForwardOutline } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { Colors } from "types/config";
import { Fund } from "types/Deposit";
import { CurrencyUtils } from "utils";

import "../overview.scss";
import firstBG from "./1-bg.jpg";
import liquidezBG from "./2-bg.jpg";
import performanceBG from "./3-bg.jpg";

const FUND_BG: any = {
  1: firstBG,
  2: liquidezBG,
  3: performanceBG
}

type Props = {
  fund: { client_id: number, fund: Fund }
  active: boolean;
  onFundSelect: (fund: any) => void;
  onClick: (fund: any) => void;
  currency: string,
  backgroundColor?: string
  buttonLink?: string
};

const FundItem: React.FC<Props> = ({ active, onClick, onFundSelect, fund, currency, backgroundColor, buttonLink }) => {
  const [hovered, setHovered]                                 = useState(active);
  const { name, id, shortage, cashout_days, initial_deposit, description } = fund.fund;
  const i                                                     = useTranslation();


  useEffect(() => {
    setHovered(active);
  }, [active]);

  return (
    <div className={"FundItem mt-1 " + (hovered ? 'active' : '')} 
      onClick={onClick}
      style={{ backgroundImage: `url(${id ? FUND_BG[id] : ''})`, backgroundColor: backgroundColor || Colors.PRIMARY }}>
      <div className="inside d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <h2 style={{ textTransform: 'uppercase' }}>
            { id === 1 ? <FirstLogo size={19.5} /> : null }
            { id === 2 ? <LiquidezLogo size={19.5} /> : null }
            { id === 3 ? <PerformanceLogo size={19.5} color={'white'} /> : null }
            &nbsp;
            <FormattedMessage id={'fund.' + name} />
          </h2>
          <IoChevronForwardOutline className="arrow" />
        </div>
        <AnimateFadeLeft show={hovered} timeout={0.85}>
          {
            id === 4
              ? <div>
                  { description }
                  <br />
                  <br />
                  <br />
                  <a href={buttonLink || "http://localhost:3000"} target="_blank" rel="noreferrer">
                    <Button type="button" color="light" outline block title={i._("fund.more_datails")} />
                  </a>
                </div>
              : <div>
                  <small className="mt-1">
                    <strong><FormattedMessage id="fund.shortage"/>: </strong> { shortage ? <FormattedMessage id="dashboard.days" values={{d: shortage}}/> : <FormattedMessage id="fund.there_is_not"/> }<br />
                    <strong><FormattedMessage id="fund.withdraw_time"/>: </strong> D+{ cashout_days }<br />
                    <strong><FormattedMessage id="fund.first_deposit"/>:</strong> { CurrencyUtils.format(initial_deposit, currency)}
                  </small>
                  <Button type="button" onClick={() => onFundSelect(fund)} color="light" outline block title={i._("fund.more_datails")} />
                </div>
          }
        </AnimateFadeLeft>
      </div>
    </div>
  );
}

export default FundItem;