import SupportRequests from "api/SupportRequests";
import CopyContent from "common/components/CopyContent";
import ActionSheet from "common/components/ui/ActionSheet";
import { useTranslation } from "hooks";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAuth } from "reducers/userReducer";

import "../deposit.scss";

type Props = {
  onClose: () => void;
}

const InvitationModal: React.FC<Props> = ({ onClose }) => {
  const authUser            = useAuth();
  const [seller, setSeller] = useState<any>(null);
  const i                   = useTranslation();


  useEffect(() => {
    function fetchSellerData() {
      SupportRequests.details()
        .then( response => setSeller(response.data.seller))
        .catch( error => console.warn('Error fetch seller'))
    }

    fetchSellerData();
  }, []);

  return (
    <ActionSheet title={i._("invitation_modal.title")} onClose={onClose}>
      <div className="mx-3 my-3 mt-2 invitation-modal">
        <FormattedMessage id="global.hello"/> <strong>{ authUser?.name.split(' ')[0] }</strong>. <br />
        <FormattedMessage id="invitation_modal.text_1"/> <br />
        <p className="mt-2">
        <FormattedMessage id="invitation_modal.text_2"/> <strong>{ seller?.name }</strong> <FormattedMessage id="invitation_modal.text_3"/>
        </p>

        <div className="highlight px-1 py-2">
          <h4><FormattedMessage id="invitation_modal.contact_information"/></h4>
          <strong><FormattedMessage id="fields.phone"/>:</strong> <CopyContent text={seller?.phone} />
        </div>
      </div>
    </ActionSheet>
  );
}

export default InvitationModal;