import React, { useEffect, useState } from "react";
import { IoOpenOutline } from "react-icons/io5";
import { RECEIPT_FILES_PREFIX } from "types/config";
import { getDecodedJWT } from "utils";
import LoadingSpinner from "./LoadingSpinner";

type Props = {
  file: string,
  fileType: 'RECEIPT',
  type: number,
  status: number
}

const RECEIPT_TYPES = [
  "Depósito - Comprovante Aporte", // 0
  "DOC - Comprovante Aporte", // 1
  "TED - Comprovante Aporte", // 2
  "Comprovante - Resgate", // 3
  "Comprovante - Pagamento Rendimento", //4
  "Comprovante Darf - Resgate", // 5
  "Comprovante IOF - Resgate", // 6
  "Contrato Aporte", // 7
  "Contrato Assessor", // 8
  "Comprovante Darf - Rendimento", // 9
  "Comprovante IOF - Rendimento", // 10
  "DARF", // 11
  "Documento", // 12
  "PIX - Comprovante Aporte", // 13
]

const FilePreview: React.FC<Props> = ({ file, status, type }) => {
  
  const [staticToken, setStaticToken] = useState<null|string>(null)

  useEffect(() => {
    getDecodedJWT()
      .then((decoded: any) => setStaticToken(decoded.sat))
      .catch(error => console.warn('Error while fetching decoded token'));
  })

  if ( !staticToken ) {
    return (
      <p>
        <LoadingSpinner />
      </p>
    )
  }
  
  return (
    <a href={file ? RECEIPT_FILES_PREFIX + file + '&token=' + staticToken: '#'} target={file ? "_blank" : ''} rel="noreferrer">
      <div className="file-preview d-flex align-items-center justify-content-between">
        <div className="file-text d-flex flex-column text-start">
          {
            process.env.REACT_APP_OFFICE_ID === '2'
              ? null
              : <strong className="text-truncate">{ RECEIPT_TYPES[type] }</strong>
          }
          <small className="text-truncate">{ file || 'Arquivo não encontrado' }</small>
        </div>
        <div className="p-1">
          {
            file ? <IoOpenOutline size={20} /> : null
          }
        </div>
      </div>
    </a>
  )
}

export default FilePreview;