import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from 'store';
import "common/styles/style.scss";

import AppRoute from 'common/components/AppRoute';
import DepositDetails from 'pages/Client/Deposit/DepositDetails';
import Logout from 'pages/Auth/Logout';
import CashoutDetails from 'pages/Client/Cashout/CashoutDetails';
import { lazy, Suspense } from 'react';
import LoadingSpinner from 'common/components/ui/LoadingSpinner';
import Ir from 'pages/Client/Ir/Ir';
import Translation from 'common/components/Translation';
import PreLoading from 'common/components/PreLoading';
import AxiosRegistry from 'common/components/AxiosRegistry';
import ReactGA from 'react-ga';
import Overview from 'pages/Client/Overview/Overview';

const Auth = lazy(() => import('pages/Auth/Auth'));
const Deposit = lazy(() => import('pages/Client/Deposit/Deposit'));
const Cashout = lazy(() => import('pages/Client/Cashout/Cashout'));
const Dashboard = lazy(() => import('pages/Client/Dashboard/Dashboard'));
const Investments = lazy(() => import('pages/Client/Investments/Investments'));
const Account = lazy(() => import('pages/Client/Account/Account'));
const Support = lazy(() => import('pages/Client/Support/Support'));
const Banks = lazy(() => import('pages/Client/Banks/Banks'));
const Terms = lazy(() => import('pages/Client/Terms/Terms'));
const ArbtrustTerms = lazy(() => import('pages/Client/Terms/ArbtrustTerms'));
const Policy = lazy(() => import('pages/Client/Terms/Policy'));
const Etichs = lazy(() => import('pages/Client/Terms/Ethic'));
const Statement = lazy(() => import('pages/Client/Statement/Statement'));
const Accept = lazy(() => import('pages/Client/Terms/Accept'));
const Onboarding = lazy(() => import('pages/Client/Onboarding/Onboarding'));
const Comunicado = lazy(() => import('pages/Client/Terms/Comunicado'));

ReactGA.initialize('G-5NVQ3X0KHR');

const Loading = () => (
  <div style={{
    display: 'flex',
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <LoadingSpinner />
  </div>
)


function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <Translation>
          <BrowserRouter>
            <AxiosRegistry>
              <PreLoading>
                <Switch>
                  <AppRoute path="/auth" component={Auth} />
                  {
                    process.env.REACT_APP_OFFICE_ID === '1' &&
                      <AppRoute path="/comunicado" guard component={Comunicado} />
                  }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/account" guard component={Account} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/deposit/:id" guard component={DepositDetails} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/deposit" guard component={Deposit} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/statement" guard component={Statement} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/banks" guard component={Banks} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/ir" guard component={Ir} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/cashout/:id" guard component={CashoutDetails} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/cashout" guard component={Cashout} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/investments" guard component={Investments} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/support" guard component={Support} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/accept" guard component={Accept} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/ethics" component={Etichs} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/policy" component={Policy} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/terms" component={Terms} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/terms_and_conditions" component={ArbtrustTerms} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/overview" guard component={Overview} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/onboarding/:code" component={Onboarding} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/logout" component={Logout} /> }
                  { process.env.REACT_APP_OFFICE_ID !== '1' && <AppRoute path="/" guard component={Dashboard} /> }
                  
                </Switch>
              </PreLoading>
            </AxiosRegistry>
          </BrowserRouter>
        </Translation>
      </Suspense>
    </Provider>
  );
}

export default App;
