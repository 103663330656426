import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { Colors } from "types/config";


const RenderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
  
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={payload.fill || fill}>
        {payload.name.toUpperCase()}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={Colors.PRIMARY_LIGHT}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={Colors.PRIMARY_LIGHT}
      />
    </g>
  );
};


type Props = { 
  active: number,
  onMouseEnter: (i: number) => void,
  data: any
}

const OverviewPie: React.FC<Props> = ({ active, onMouseEnter, data }) => {

  const [activeIndex, setActiveIndex] = useState(data.findIndex((i: any) => i.id === active));

  useEffect(() => {
    if ( data.length === 1 && data[0].id === 0 ) {
      setActiveIndex(0);
    } else {
      setActiveIndex(data.findIndex((i: any) => i.id === active));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const onHover = (_: any, index: number) => {
    if ( _.id ) {
      onMouseEnter(_.id);
    }
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={250}>
        <PieChart width={400} height={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={RenderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onHover}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}

export default OverviewPie;