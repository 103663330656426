import React from 'react';
import { useCurrentFund } from 'reducers/navigationReducer';

export type CardProps = {
  children?: any,
  className?: string,
  title?: string,
  fullMobile?: boolean,
  subtitle?: string,
  onClick?: () => void,
  border?: boolean,
  onTouchEnd?: () => void,
  style?: any
}

const Card: React.FC<CardProps> = ({ children, className, onClick, onTouchEnd, title, subtitle, fullMobile, style, border = false }) => {

  const fund = useCurrentFund();
  const boderClass = 'border-' + (fund && fund.id === 1
    ? 'primary'
    : fund && fund.id === 2
    ? 'accent'
    : 'primary-light');
    
  return (
    <div className={"card" + (className ? ' ' + className : '') + (fullMobile ? " full-mobile" : "")}
      onClick={() => onClick ? onClick() : null} 
      onTouchEnd={() => onTouchEnd ? onTouchEnd() : null}
      style={border ? { ...style, overflow: 'hidden' } : style}
      >
      <div className={"card-body " + (border ? boderClass : '')}>
        {
          title
            ? <h5 className="card-title">{ title }</h5>
            : null
        }
        {
          subtitle
            ? <h6 className="card-subtitle mb-1">{ subtitle }</h6>
            : null
        }
        {

        }
        { children }
      </div>
    </div>
  );
}

export default Card;