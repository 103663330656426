import { extractData } from "api";
import CashoutRequests from "api/CashoutRequests";
import RectLoader from "common/components/RectPlaceholder";
import { Col, Row } from "common/components/ui/Layout";
import useDebounce from "hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CurrencyUtils } from "utils";

type Props = {
  value: string,
  type: string
}

type DarfInformation = {
  darf: number,
  aliquot: number
  net_value: number,
  penalty: number,
  darfTax: boolean
}

const TaxEstimate: React.FC<Props> = ({ value, type }) => {

  const [netValue, setNetValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [calculatedValue, setCalculatedValue] = useState<DarfInformation>({
    darf: 0,
    aliquot: 0,
    penalty: 0,
    net_value: 0,
    darfTax: false
  });

  const taxValue = useDebounce(netValue, 2600);


  useEffect(() => {
    if ( value !== '' ) {
      let formattedValue = type === 'BRL' 
        ? value.replace(/\./g, '').replace(',', '.')
        : value.replace(',', '');

      setLoading(true);
      setNetValue(parseFloat(formattedValue));
    }
  }, [value, type]);

  useEffect(() => {
    CashoutRequests.estimate(netValue, type)
      .then(extractData)
      .then((data: any) => {
          if ( data.tax ) {
            setCalculatedValue({
              net_value: data.tax.value - data.tax.darf - data.penalty,
              darfTax: true,
              darf: data.tax.darf,
              aliquot: data.tax.darfAliquot,
              penalty: data.penalty
            });
          } else {
            setCalculatedValue({
              net_value: netValue - data.penalty,
              darfTax: false,
              darf: 0,
              aliquot: 0,
              penalty: data.penalty
            });
          }
          setLoading(false);
      })
      .catch(err => console.log(err));
    // eslint-disable-next-line
  }, [taxValue]);

  if ( value === '' ) return null;

  return (
    <Row className="mb-3">
      <Col sm={12} className="d-flex justify-content-between">
        <div>
          {
            type === 'BRL'
              ? <>
                  DARF: <br />
                  <FormattedMessage id="global.aliquot" />: <br />     
                </>
              : null
          }
          <FormattedMessage id="cashout.fine" />: <br />
          <FormattedMessage id="cashout.estimate_net" />: <br />
        </div>
        {
          loading
            ? <div style={{ textAlign: 'right' }}>
                {
                  type === 'BRL'
                    ? <>
                        <RectLoader width={75} height={20} /> <br />
                        <RectLoader width={35} height={20} /> <br />
                      </>
                    : null
                }
                <RectLoader width={75} height={20} /> <br />
                <RectLoader width={100} height={20} /> 
              </div>
            : <div className="d-flex flex-column align-items-end">
                {
                  type === 'BRL'
                    ? <>
                        <strong className="text-danger">- { CurrencyUtils.format(calculatedValue.darf, type) }</strong>
                        <strong>{ calculatedValue.darf === 0 ? '0%' : calculatedValue.aliquot + '%' }</strong>    
                      </>
                    : null
                }
                <strong className="text-danger">- { CurrencyUtils.format(calculatedValue.penalty, type) }</strong>
                <strong>{ CurrencyUtils.format(calculatedValue.net_value, type) }</strong>
              </div>
        }
      </Col>
    </Row>
  )
}

export default TaxEstimate;