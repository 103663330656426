import { api, getRequestOptions } from ".";

export default class LatamRequests {
  static price() {
    return api.get('/latam/get-prices', getRequestOptions());
  }

  static create(data: any) {
    return api.post(`/latam/create-pix`, data, getRequestOptions())
  }

  static get(id: any) {
    return api.get('/latam/get-order/' + id, getRequestOptions())
  }
}