import CopyContent from "common/components/CopyContent";
import React from "react";
import { FormattedMessage } from "react-intl";

const ForeignBankItem = ({ ...bank }) => {
  return (
    <>
      <h4 className="text-center mt-3">
        <FormattedMessage id="deposit.option" values={{ d: bank.option }} />
      </h4>
      <ul className="listview flush transparent simple-listview no-space mt-1">
        <li>
          <strong>
            <FormattedMessage id="bank.wire_routing_number" />
          </strong>
          <CopyContent text={bank.wire_routing_number} />
        </li>
        <li>
          <strong>
            <FormattedMessage id="bank.account_number" />
          </strong>
          <CopyContent text={bank.account_number} />
        </li>
        <li>
          <strong>
            <FormattedMessage id="bank.account_title" />
          </strong>
          <CopyContent text={bank.owner_account} />
        </li>
        <li>
          <strong>
            <FormattedMessage id="bank.bank_address" />
          </strong>
          <CopyContent text={bank.bank_address} wrap />
        </li>
        {
          bank.type === 'corrente'
            ? <li>
                <strong>
                  <FormattedMessage id="bank.select_bank_account" />
                </strong>
                <FormattedMessage id="bank.checking_acc" />
              </li>
            : null
        }
      </ul>
    </>
  )
}

export default ForeignBankItem;