import axios from 'axios';
import { NavigationTypes } from 'reducers/navigationReducer';
import AppStorage from 'utils/AppStorage';
import { getCurrentFundClient, getDecodedJWT, readJWT } from 'utils/AuthUtils';
import ReactGA from 'react-ga';

const HANDLED_ERRORS = [404, 400, 500, 403];

export const api = axios.create({
  // baseURL: 'http://dev.app.mkcapital.com.br/api/v2',
  baseURL: process.env.REACT_APP_OFFICE_ID === '1'
    ? 'https://app.sbarainicapital.com.br/api/v2'
    : 'https://app.arbtrust.fund/api/v2'
});

const DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const getRequestOptions = (customOptions: any = {}) => {

  const token = AppStorage.get('token');
  let headers: any = getDefaultConfig();
  const clientFund = getCurrentFundClient();

  headers['X-Oid'] = process.env.REACT_APP_OFFICE_ID || 1;

  if (clientFund) headers['X-Fund-Client'] = clientFund;
  if (token) headers['Authorization'] = 'Bearer ' + token;

  const locale = AppStorage.get('locale');
  if (locale) headers['Accept-Language'] = locale;

  return {
    headers: headers,
    ...customOptions
  };
}

export const getDefaultConfig = () => {
  return {
    'X-Oid': process.env.REACT_APP_OFFICE_ID || 1
  }
}

export const refreshToken = () => new Promise((resolve, reject) => {
  api.post('/auth/refresh', {}, getRequestOptions())
    .then(response => {
      readJWT(response.data.token).then(decoded => {
        resolve(response.data.token);
      });
    })
    .catch(error => {
      AppStorage.delete('token');
      AppStorage.delete('val');
      resolve(error);
    });
});

/**
 * 
 * @param routeURL 
 * @returns 
 */
const needsRefresh = async () => {
  const decoded: any = await getDecodedJWT();

  if ( decoded ) {
    const dateNow = (new Date()).getTime() / 1000;

    if (dateNow > decoded.exp) {
      return true;
    }
  }

  return false;
}


export const setupAxiosInterceptors = (history: any, dispatch: any) => {

  // Request Interceptor
  api.interceptors.request.use(async function (request) {
    if ( DEV ) {
      console.log('Request ' + request.method?.toUpperCase() + ' to ' + request.url);
    }

    return request;
  });

  // Response interceptor
  api.interceptors.response.use(async function (response) {
    return response;
  }, async function (error) {

    if ( DEV ) console.log(error);
    
    const originalConfig = error.config;

    if (!error.response ) {
      if ( navigator.onLine !== true ) {
        document.location.href = '/offline.html';
      } else {
        ReactGA.event({
          category: 'Error',
          action: '500 Error',
          label: error.message
        });
        //document.location.href = '/error.html';
      }
    }

    const { data } = error.response;
    const routeURL = error.response.config.url;

    const exceptRoutes = [
      '/auth/refresh',
      '/auth/login',
      '/auth/verify'
    ];
    
    if (typeof routeURL === 'string' && exceptRoutes.indexOf(routeURL) === -1 && !originalConfig._retry) {
      originalConfig._retry = true;

      if (data?.message === 'EXPIRED') {

        if ( await needsRefresh() && !(window as any).__REFRESHING) {
          (window as any).__REFRESHING = true;
          await refreshToken();
          document.location.reload();
        }
      } else if (error.response.status === 401) {
        AppStorage.delete('token');
        AppStorage.delete('val');
        document.location.reload();
      }
    }

    if ( HANDLED_ERRORS.indexOf(error.response.status) !== -1 && originalConfig.method === 'get' ) {
      
      if ( error.response.data && error.response.data.message === 'TERMS' ) {
        history.push('/accept');
        return Promise.reject(error.response);
      }

      if ( originalConfig.skipErrors && originalConfig.skipErrors.indexOf(error.response.status) !== -1 ) {
        return Promise.reject(error.response);
      }

      dispatch({ 
        type: NavigationTypes.THROW_API_ERROR,
        payload: {
          message: data?.message,
          status: error.response.status,
          goBack: 'n'
        }
      });
    }

    return Promise.reject(error.response);
  });
}

export const extractData = (response: any) => {
  if ( response && response.data ) {
    return response.data;
  } else {
    return {};
  }
}

export { default as authRequests } from './authRequests';
export { default as ReportRequests } from './ReportRequests';
//export { default as ClientRequest } from './ClientRequest';
