import useTranslation from "hooks/useTranslation";
import React, { ChangeEvent } from "react";

export type MultiButtonOptionProps = {
  value: string,
  description: string
};

type Single = {
  name?: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  option: MultiButtonOptionProps,
  value?: string
  translate?: boolean
}

type Props = {
  label?: string,
  block?: boolean,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string,
  id?: string,
  value?: string,
  disabled?: boolean,
  className?: string,
  loading?: boolean,
  translate?: boolean,
  options: {
    value: string,
    description: string
  }[]
}

const MultiButtonSigle: React.FC<Single> = ({ onChange, name, option, value, translate = true }) => {
  const random = Math.random().toString(36).substring(7);
  const i = useTranslation();

  return (
    <>
      <input type="radio" className="btn-check" onChange={onChange} name={name} 
            id={random} value={option.value} checked={value === option.value} />
      <label className="btn btn-outline-primary" htmlFor={random}>
        { translate ? i._(option.description) : option.description }
      </label>
    </>
  )
}

const MultiButton: React.FC<Props> = ({ options, block, onChange, name, id, value, className, disabled, loading, label, translate = true }) => {

  return (
    <div className={"btn-group " + (block ? 'btn-block' : '') + (className ? ' ' + className : '')} role="group">
      {
        options
          ? options.map((option, index) => (
            <MultiButtonSigle 
              name={name} 
              onChange={e => !disabled && !loading && onChange ? onChange(e) : null} 
              option={option}
              value={value}
              translate={translate}
              key={'mb-'+ index} />
          ))
          : null
        }
    </div>
  );
}

export default MultiButton;