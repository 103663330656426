import React from 'react';
import Container from 'common/components/Container';
import { Col, Row } from 'common/components/ui/Layout';
import useTranslation from 'hooks/useTranslation';
import ManualReport from './components/ManualReport';
import ReportList from './components/ReportList';
import { useAuth } from 'reducers/userReducer';
import ReportListDirf from './components/ReportListDirf';


const Ir = () => {
  const i = useTranslation();
  const user = useAuth();

  const hideReports = !user || user.variable_income !== 1;

  const pending = false;

  return (
    <Container title={i._('ir.title', { v: ''})}>
      <Row>
        <Col md={8}>
          <ManualReport />
          {
            process.env.REACT_APP_OFFICE_ID !== '2'
              ? <ReportListDirf pending={false} />
              : null
          }
          {
            !hideReports && process.env.REACT_APP_OFFICE_ID !== '2'
              ? <ReportList pending={pending} />
              : null
          }
        </Col>
      </Row>
    </Container>
  )
}

export default Ir;