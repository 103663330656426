import React, { useEffect } from 'react';
import Container from 'common/components/Container';
import DepositStatus from './components/DepositStatus';
import CurrencyUtils from 'utils/CurrencyUtils';

import "./deposit.scss";
import { useParams } from 'react-router';
import DepositRequests from 'api/DepositRequests';
import { Col, Row } from 'common/components/ui/Layout';
import DepositReceipt from './components/DepositReceipt';
import { useDispatch, useSelector } from 'react-redux';
import { ClientTypes } from 'reducers/clientReducer';
import { ApplicationState } from 'store';
import Card from 'common/components/ui/Card';
import DepositInformation from './components/DepositInformation';
import Alert from 'common/components/ui/Alert';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import useTitle from 'hooks/useTitle';
import { IoAlertCircleOutline, IoArrowUpOutline } from 'react-icons/io5';
import AppStorage from 'utils/AppStorage';
import useTranslation from 'hooks/useTranslation';

const Loading = ({ ...props }) => (
  <ContentLoader
      speed={2}
      width={props.width || 116}
      height={27}
      viewBox={"0 0 " + (props.width || 116) + " 27"}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
    <rect x="1" y="-1" rx="3" ry="3" width={props.width || 116} height="27" />
  </ContentLoader>
)

const DepositDetails = () => {
  const i = useTranslation();
  useTitle(i._('global.details'));
  const { id }: any   = useParams();
  const dispatch      = useDispatch();
  const deposit       = useSelector((state: ApplicationState) => state.client.deposit);
  const createdAt = deposit?.created_at ? new Date(deposit.created_at.replace(/-/g, "/")) : null;

  useEffect(() => {
    DepositRequests.get(id)
      .then( response => dispatch({ type: ClientTypes.UPDATE_DEPOSIT, payload: response.data }))
      .catch( error => console.warn('Depósito não recuperado'));

    return () => {
      dispatch({ type: ClientTypes.UPDATE_DEPOSIT, payload: { receipt: [] } });
    }
  }, [id, dispatch]);

  console.log(deposit)

  return (
    <Container title={i._('global.deposit')} goBackLink="/deposit">
      <Row>
        <Col sm={8}>
          <Card fullMobile title={i._('deposit.informations')} className="mb-3">
            <div className="icon-wrapper">
              <div className="iconbox">
                <IoArrowUpOutline />
              </div>
            </div> 
            <ul className="listview flush transparent simple-listview no-space mt-3">
              <li>
                <strong>Status</strong>
                { 
                  deposit.status || deposit.status === 0 
                    ? <DepositStatus status={deposit.status} reaport={deposit.sub_type === 'reaporte'} /> 
                    : <Loading />
                }
              </li>
              <li>
                <strong>
                  <FormattedMessage id="global.method" />
                </strong>
                {
                  deposit.status || deposit.status === 0 
                  ? <span>
                      <FormattedMessage id={deposit.type === 'BRL' ? 'bank.payment_type_ted_pix' : 'bank.payment_type'} />
                    </span>
                  : <Loading width={180}/>
                }
              </li>
              
              <li>
                <strong>
                  <FormattedMessage id="global.date" />
                </strong>
                { 
                  createdAt
                    ? <span>{ createdAt?.toLocaleDateString(AppStorage.get('locale') || undefined) }</span>
                    : <Loading width={105} />
                }
              </li>
              <li>
                <strong>
                  <FormattedMessage id="global.value" />
                </strong>
                <h3 className="m-0">
                  { 
                    deposit.value 
                      ? CurrencyUtils.format(deposit.value, deposit.type) 
                      : <Loading width={130} /> 
                  }
                </h3>
              </li>
            </ul>
            
            { 
              deposit.detailts
                ? <>
                    <h5 className="mt-3">
                      <FormattedMessage id="deposit.note" />:
                    </h5>
                    <p>
                      { deposit.detailts }
                    </p>
                  </>
                : null
            }

          </Card>


          {
            deposit.status === 0
              ? <Card title={i._('deposit.payment')} fullMobile>
                  <DepositInformation deposit={deposit} />
                </Card>
              : null
          }
          {
            deposit.status === 4
              ? <Alert icon={<IoAlertCircleOutline size={30} style={{ marginRight: 10 }} />} 
                  message={i._('deposit.pending')} 
                  color="primary" 
                  simple />
              : null
          }
        </Col>
        <Col sm={4}>
          {
            deposit.receipt.length > 0 || deposit.status === 0
              ? <Card fullMobile>
                  <DepositReceipt />
                </Card>
              : null
          }
        </Col>
      </Row>
    </Container>
  );
}

export default DepositDetails;