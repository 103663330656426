import React, { useEffect, useRef, useState } from "react";

type DropdownItemProps = {
  title: string,
  value: string,
  icon?: any,
  onClick?: (value: string) => void
}

type DropdownProps = {
  children?: any,
  title: any,
  onItemSelected?: (value: string) => void,
  select?: boolean,
  items?: DropdownItemProps[],
  icon?: any,
  className?: string,
  iconMobile?: boolean
}

export const DropdownItem: React.FC<DropdownItemProps> = ({ title, value, onClick, icon }) => (
  <div className="dropdown-item" onClick={() => onClick ? onClick(value) : null}>
    {
      icon
        ? icon
        : null
    }
    { title }
  </div>
);


const Dropdown: React.FC<DropdownProps> = ({ children, title, select, items, onItemSelected, className, icon, iconMobile }) => {

  const [open, setOpen] = useState(false);
  const ref: any = useRef();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if ((ref.current && !ref.current.contains(event.target))) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onItemClick = (v: string) => {
    if ( onItemSelected ) {
      setOpen(false);
      onItemSelected(v);
    }
  }

  return (
    <div className={"dropdown " + className}>
      <button className={"btn btn-primary dropdown-toggle " + (open ? 'show' : '')} onClick={() => setOpen(!open)} type="button">
          { icon ? icon : null } 
          <span className={iconMobile ? 'd-none d-md-block' : ''}>{ title }</span>
      </button>
      <div className={"dropdown-menu dropdown-menu-right " + (open ? 'show' : '')} ref={ref}>
        {
          select && items
            ? items.map( (item, itemIndex) => <DropdownItem onClick={onItemClick} {...item} />)
            : children
        }
      </div>
    </div>
  )
}

export const DropdownDivider = () => (
  <div className="dropdown-divider"></div>
);



export default Dropdown;
