import React from "react"
import { IoBanOutline, IoSearchOutline } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { ApiError } from "reducers/navigationReducer";
import { ApplicationState } from "store";
import { Col, Row } from "./ui/Layout";
import ReactGA from "react-ga";

const STATUS_ICONS: any = {
  403: <IoBanOutline size={40} />,
  404: <IoSearchOutline size={40} />
}

const ErrorMessage = () => {

  const apiError: ApiError = useSelector((state: ApplicationState) => state.navigation.apiError);
  
  ReactGA.event({
    category: 'Error',
    action: '' + apiError.status
  });

  return (
    <Row>
      <Col className="text-center d-flex flex-column align-items-center pt-2">
        { apiError.status ? STATUS_ICONS[apiError.status] : '' }
        <h4 className="mt-2">
          <FormattedMessage id={"global.api." + apiError.status} />
        </h4>
        <span>
          <FormattedMessage id={"global.api.desc." + apiError.status} />
        </span>
      </Col>
    </Row>
  )
}

export default ErrorMessage;