import React, { useEffect, useState } from "react";
import "./components.scss";
import { SbarainiLogo } from "./logos";
import LoadingSpinner from "./ui/LoadingSpinner";
import { authRequests, refreshToken } from "api";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import { ApplicationState } from "store";
import { getCurrentFundClient, getDecodedJWT } from "utils/AuthUtils";
import ClientRequest from "api/ClientRequest";
import { UserTypes } from "reducers/userReducer";
import CurrencyUtils from "utils/CurrencyUtils";
import AppStorage from "utils/AppStorage";
import ArbLogo from "./logos/ArbLogo";

enum LOGIN_REDIRECT {
  NONE = '/auth/login',
  EXPIRED = '/auth/login?r=expired'
}

const EXCEPT_ROUTES = [
  '/auth/update/',
  '/auth/verify',
  '/auth/recovery',
  '/onboarding'
];


type Props = {
  children: any
};

const routeMatch = (currentRoute: string, routes: string[]) => {
  const matchedRoutes = routes.filter( route => currentRoute.indexOf(route) !== -1);
  return matchedRoutes.length > 0
    ? matchedRoutes[0]
    : false
}


const PreLoading: React.FC<Props> = ({ children }) => {

  const history               = useHistory();
  const location              = useLocation();
  const [spinner, setSpinner] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [show, setShow]       = useState(true);
  const dispatch              = useDispatch();
  const tokenCheck            = useSelector((state: ApplicationState) => state.navigation.tokenCheck);

  const finish = () => {
    setSpinner(false);
    setExiting(true);
    setTimeout(() => setShow(false), 500);
  }

  useEffect(() => {
    async function verifyJWT() {
      
      setSpinner(true);
      
      // Force login with GET parameters
      const params = new URLSearchParams(location.search);
      const forceToken = params.get('t');
      if ( forceToken ) {
        AppStorage.delete('fcl');
        AppStorage.set('token', forceToken);
      }

      let pushRoute = null;
      
      // Check token is empty
      const token = AppStorage.get('token');
      if ( !token || token === '' ) {
        pushRoute = LOGIN_REDIRECT.NONE;
      }
      
      try {
        // Check token is expired
        const { data } = await authRequests.verify();

        // Refresh token if need
        if ( data.status === 'REFRESH' ) {
          await refreshToken();
        }
        
        const decoded: any = await getDecodedJWT();
        const client       = await ClientRequest.get(decoded?.cut?.id);
        const fund         = getCurrentFundClient();

        dispatch({ type: UserTypes.UPDATE_BETA, payload: decoded.bt });

        // If user not defined anyone fund, redirect to overview
        if ( !fund ) { 
          if ( decoded.bt ) {
            if (process.env.REACT_APP_OFFICE_ID === '1') {
              history.push('/comunicado');
            } else {
              history.push('/overview');
            }
          } else {
            AppStorage.set('fcl', client.data.available_funds[0].client_id);
            dispatch({ type: NavigationTypes.UPDATE_FUND, payload: client.data.available_funds[0].fund });
          }
        } else {
          const fundDetails = client.data.available_funds.filter((f: any) => f.client_id == fund)[0].fund;
          dispatch({ type: NavigationTypes.UPDATE_FUND, payload: fundDetails });
        }
        
        
        dispatch({ type: UserTypes.UPDATE_CURRENCY_TYPE, payload: CurrencyUtils.getFirstCurrency(client.data)});
        dispatch({ type: UserTypes.UPDATE_AUTH_USER, payload: client.data });
        dispatch({ type: NavigationTypes.UPDATE_TOKEN_CHECK, payload: true });
        return () => {};
      } catch ( error: any ) {
        if ( error?.data?.message === 'NOT PROVIDED' ) {
          pushRoute = LOGIN_REDIRECT.NONE + window.location.search;
        } else if( error?.data?.message === 'EXPIRED' ) {
          pushRoute = LOGIN_REDIRECT.EXPIRED;
        } else {
          pushRoute = LOGIN_REDIRECT.NONE + window.location.search;
        }
      }
      
      if ( pushRoute ) history.push(pushRoute);
      finish();
  }
    if ( !routeMatch(location.pathname, EXCEPT_ROUTES) ) {
      setTimeout(() => verifyJWT(), 1000);
    } else {
      finish();
    }
  }, []);

  useEffect(tokenCheck ? finish : () => {}, [tokenCheck]);

  return show 
      ? <div className={"preloading " + (exiting ? 'exit' : '')}>
          <div className={"animation"}>
            {
              process.env.REACT_APP_OFFICE_ID !== '2'
                ? <SbarainiLogo size={65} text={false} className="mb-2" />
                : <ArbLogo />
            }
          </div>
          <div style={{ visibility: spinner ? 'visible' : 'hidden' }}>
            <LoadingSpinner />
          </div>
        </div>
      : children;
}

export default PreLoading;