import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationTypes } from 'reducers/navigationReducer';
import { ApplicationState } from 'store';
import { ColorsProps } from './GeneralProps';

type AlertProps = {
  message?: string|null,
  className?: string,
  outline?: boolean
  close?: boolean,
  translate?: boolean
  simple?: boolean,
  icon?: React.ReactNode
  color?: string
}

type Props = AlertProps & ColorsProps;

const Alert: React.FC<Props> = ({ color, message, icon, className, outline, close, translate = false, simple = false }) => {

  const alert    = useSelector((state: ApplicationState) => state.navigation.alert as any);
  const onClose  = () => dispatch({ type: NavigationTypes.UPDATE_ALERT, payload: '' });

  const classes = [
    'alert',
    'alert-' + (outline ? 'outline-' : '') + (color || alert.color),
    (close) ? 'alert-dismissible' : '',
    className
  ];

  const dispatch = useDispatch();
  
  

  if ( !alert && !simple ) {
    return <></>;
  }

  message = message || alert.message;

  return (
    <div className={classes.join(' ')} role="alert">
      {
        icon
      }
      {
        translate && message
          ? <FormattedMessage id={message} />
          : message
      }
      {
        close 
          ? <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
          : null
      }
    </div>
  )
}

export default Alert;