import React, { FormEvent, useState } from 'react';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { Colors } from 'types/config';
import { applyMask, MaskProp } from './masks';

type TextInputProps = {
  label?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  type: 'password' | 'text';
  value?: string;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  error?: string;
  disabled?: boolean;
  rightIcon?: React.ReactNode;
  prefix?: string;
  autoComplete?: 'on'|'off';
  maxLength?: number;
}

type Props = TextInputProps & MaskProp;

const TextInput: React.FC<Props> = ({ label, id, placeholder, type, value, onChange, name, error, mask, disabled, maxLength, rightIcon, autoComplete = 'on' }) => {

  const inputProps = {
    id, placeholder, type, value, name, disabled, maxLength
  };

  const [showPassword, setShowPassword] = useState(type === 'password' ? false : true);

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value;

    e.currentTarget.value = applyMask(value, { mask });

    if ( onChange ) onChange(e);
  }

  return (
    <div className="form-group basic">
      <div className="input-wrapper">
        {
          label
            ? <label className="label" htmlFor={id}>{label}</label>
            : null
        }
        <input 
          {...inputProps} 
          onChange={ e => mask ? handleInputChange(e) : onChange(e) }
          spellCheck={false}
          autoCorrect="off"
          autoComplete={autoComplete}
          autoCapitalize="off" 
          type={type === 'password' && !showPassword ? 'password': 'text'} 
          className="form-control" />
        {
          error && error !== ''
            ? <small className="error">{error}</small>
            : null
        }
        {
          rightIcon
            ? rightIcon
            : null
        }
        {
          type === 'password' && !showPassword
            ? <i className="clear-input" onClick={() => setShowPassword(true)}>
                <IoEyeOutline color={Colors.PRIMARY} />
              </i>
            : null
        }
        {
          type === 'password' && showPassword
            ? <i className="clear-input" onClick={() => setShowPassword(false)}>
                <IoEyeOffOutline color={Colors.PRIMARY} />
              </i>
            : null
        }
      </div>
    </div>
  );
}

export default TextInput;