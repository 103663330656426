import { DepositUtils } from "types/Deposit";
import React from "react";
import useTranslation from "hooks/useTranslation";
import { IoAlertCircleOutline } from "react-icons/io5";

type StatusTagProps = {
  status: number;
  reaport?: boolean
}

const DepositStatus: React.FC<StatusTagProps> = ({ status, reaport = false }) => {
  const i = useTranslation();
  const statusClass = 
    status === 0  ? 'text-warning' :
    status === 1  ? 'text-success' :
    status === 2  ? 'text-danger'  :
    status === 4  ? 'text-primary' :
    status === 5  ? 'text-warning' :
                    'text-info';

  return (
    <strong className={"deposit-status-text " + statusClass}>
      { i._(DepositUtils.getStatusName(status)) } 
      { reaport ? ' / ' + i._('deposit.reaport') : ''}
      {
        status === 4
          ? <IoAlertCircleOutline size={20} />
          : null
      }
    </strong>
  )
}

export default DepositStatus;