import RectLoader from "common/components/RectPlaceholder";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useCurrentFund } from "reducers/navigationReducer";
import { useAmounts } from "reducers/userReducer";
import CurrencyUtils from "utils/CurrencyUtils";

type Props = {
  type: string
}

const CashoutAvailableAmount: React.FC<Props> = ({ type }) => {

  const amounts                             = useAmounts();
  const spanRef: any                        = useRef();
  const [value, setValue]                   = useState<any>(null);
  const [formattedValue, setFormattedValue] = useState<any>(null);
  const fund                                = useCurrentFund();
  // Format Amounts values 
  const getAmount = (type: string) => {
    if ( amounts ) {
      const pending = amounts['pending_' + type.toLowerCase()];
      const amount = amounts[type.toLowerCase()];
      const total = amount - pending;
      return total;
    }

    return 0;
  }

  const animate = (start: number, end: number, duration: number, type: string) => {
    let startTimestamp: any = null;
    const step = (timestamp: any) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const newValue = progress * (end - start) + start;
      setFormattedValue(CurrencyUtils.format(newValue, type));
 
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }
  
  useEffect(() => {
    setValue((prevValue: any) => {
      const newAmount = getAmount(type);
      
      if (prevValue || newAmount) animate(prevValue || 0, newAmount, 750, type);
      else setFormattedValue(CurrencyUtils.format(newAmount, type));

      return newAmount;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amounts, type, fund]);

  if ( isNaN(value) ) {
    return <RectLoader width={300} height={24} />
  }
  
  return (
    <h4>
      <span className="text-success" ref={spanRef}>
        { formattedValue }&nbsp;
      </span>
      <FormattedMessage id="cashout.available_for_cashout" />
    </h4>
  );
}

export default CashoutAvailableAmount;