import { useTranslation } from "hooks";
import React from "react";
import ContentLoader from "react-content-loader";

type Props = {
  width?: number,
  height?: number,
  className?: string
  animate?: boolean,
  style?: React.CSSProperties
}

const RectLoader: React.FC<Props> = ({ width, height, className, style = {}, animate = true }) => {
  const { locale } = useTranslation();

  const customStyle = locale === 'ar' ? { transform: 'scaleX(-1)' } : {};

  return (
    <ContentLoader 
      speed={2}
      className={className}
      width={width}
      height={height}
      animate={animate}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="2" y="-1" rx="3" ry="3" style={{ ...customStyle, ...style }} width={width} height={height} />
    </ContentLoader>
  )
}

export default RectLoader