import ClientRequest from "api/ClientRequest";
import { useTranslation } from "hooks";
import React, { useState } from "react";
import { IoChevronForward } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import { useAuth, UserTypes } from "reducers/userReducer";
import { ApplicationState } from "store";
import { AppStorage } from "utils";
import FundUtils from "utils/FundUtils";

import "./components.scss";
import { FirstLogo, LiquidezLogo, PerformanceLogo } from "./logos";

type Props = {
  className?: string
}

type FundItemProps = {
  fund: any,
  client_id: number,
  onSwitch: (fund: any) => void
}

export const FUND_LOGOS: any = {
  1: <FirstLogo size={20} />,
  2: <LiquidezLogo size={20} />,
  3: <PerformanceLogo color="white" size={20} />
}

const FundItem: React.FC<FundItemProps> = ({ fund, client_id, onSwitch}) => {

  const dispatch = useDispatch();
  const i = useTranslation();

  const switchFund = async () => {
    onSwitch(fund);
    AppStorage.set('fcl', client_id.toString());
    dispatch({ type: NavigationTypes.UPDATE_FUND, payload: fund });
    dispatch({ type: UserTypes.UPDATE_AUTH_USER, payload: null });

    try {
      const { data } = await ClientRequest.get(client_id);
      dispatch({ type: UserTypes.UPDATE_AUTH_USER, payload: data });
    } finally {
      console.log('erro')
    }
  }

  return (
    <div className="fund-switcher__list-item d-flex align-items-center" onClick={switchFund}>
      <div className={"fund-switcher__icon"}>
        {FUND_LOGOS[fund.id]}
      </div>
      { i._('fund.' + fund.name) }
    </div>
  )
}

const FundSwitcher: React.FC<Props> = ({ className }) => {
  const currentFund = useSelector((state: ApplicationState) => state.navigation.fund);
  const [open, setOpen] = useState(false);
  const authUser = useAuth();
  const i = useTranslation();

  const toggle = () => setOpen(!open);

  if (!currentFund) {
    return <></>;
  }

  return (
    <div className={"fund-switcher " + className}>
      <div className={"fund-switcher__selected d-flex align-items-center justify-content-between " + (open ? "active" : "") + ' ' + FundUtils.getBackgroundColor(currentFund.id)}
        onClick={toggle}>
        <div className="d-flex align-items-center">
          <strong>
            { i._('fund.' + currentFund.name).toUpperCase() } <br />
          </strong>
        </div>
        <div className="d-flex align-items-center">
          <small><FormattedMessage id="fund.switch"/><br /><FormattedMessage id="fund.patrimony"/></small>
          <IoChevronForward className={"icon-arrow " + (open ? 'open' : '')} />
        </div>
      </div>
      {
        open
          ? <div className="fund-switcher__list">
              <h5><FormattedMessage id="fund.switch_for"/></h5>
              {
                authUser
                  ? authUser.available_funds.map( (f: any, c: number) => <FundItem onSwitch={() => setOpen(false)} key={c} {...f} /> )
                  : null
              }
            </div>
          : null
      }
    </div>
  );
}

export default FundSwitcher;