import React from "react";

type Props = {
  className?: string,
  style?: any 
}

const LoadingSpinner: React.FC<Props> = ({ className, style }) => (
  <div className={"spinner-border text-primary " + className} style={style} role="status"></div>
)

export default LoadingSpinner;