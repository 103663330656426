import { Colors } from "types/config";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import { ApplicationState } from "store";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

type Props = {
  className?: string,
  color?: 'primary'
}

const style = {
  cursor: 'pointer'
}


const HiddenValues: React.FC<Props> = ({ className }) => {
  const showValues   = useSelector((state: ApplicationState) => state.navigation.showValues);
  const dispatch     = useDispatch();
  const toggleValues = () => dispatch({ type: NavigationTypes.TOGGLE_VALUES });

  if ( showValues ) {
    return <IoEyeOffOutline size={20} style={style} className={className} onClick={toggleValues} color={Colors.PRIMARY} />;
  } else {
    return <IoEyeOutline size={20} style={style} className={className} onClick={toggleValues} color={Colors.PRIMARY} />;
  }

}

export default HiddenValues;