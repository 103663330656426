import Card from "common/components/ui/Card";
import { useTranslation } from "hooks";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CashoutTransaction as ICashoutTransaction } from "types/Deposit";
import { AppStorage, CurrencyUtils } from "utils";

type Props = {
  transaction: ICashoutTransaction
}

const CashoutTransaction: React.FC<Props> = ({ transaction }) => {

  const { _ } = useTranslation();

  const payedAt = transaction.date ? new Date(transaction.date.replace(/-/g, "/")) : new Date();

  return (
    <Card className="mt-3" title={_('deposit.transfer_details')}>
      <ul className="list-group mt-3 mb-3">
        <li className="list-group-item d-flex justify-content-between">
          <strong>
            <FormattedMessage id="global.date" />
          </strong>
          <span className="float-right">
            { payedAt.toLocaleDateString(AppStorage.get('locale') || undefined) }
          </span>
        </li>
        {
          transaction.bank
            ? <>
                <li className="list-group-item d-flex justify-content-between">
                  <strong>
                    <FormattedMessage id="fields.bank" />
                  </strong>
                  <span>
                    { transaction.bank.name }
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <strong>
                    <FormattedMessage id="fields.agency" />
                  </strong>
                  <span>
                    { transaction.bank.agency }
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <strong>
                    <FormattedMessage id="fields.account" />
                  </strong>
                  <span>
                    { transaction.bank.account }
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <strong>
                    <FormattedMessage id="incomes.net_value" values={{ v: '' }} />
                  </strong>
                  <span>
                    { CurrencyUtils.format(transaction.liquid_value, 'BRL') }
                  </span>
                </li>
              </>
            : null
        }
      </ul>
    </Card>
  )
}

export default CashoutTransaction;