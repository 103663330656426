import React from "react";
import { LayoutProps } from "../GeneralProps";

type ColProps = {
  children?: any,
}

type Props = LayoutProps & ColProps;

const Col: React.FC<Props> = ({ sm, md, lg, xs, xxl, xl, children, className }) => {

  const classes: any = [];

  if ( sm ) classes.push('col-sm-' + sm);
  if ( md ) classes.push('col-md-' + md);
  if ( lg ) classes.push('col-lg-' + lg);
  if ( xl ) classes.push('col-xl-' + xl);
  if ( xxl ) classes.push('col-xxl-' + xxl);
  if ( xs ) classes.push('col-' + xs);
  if ( className ) classes.push(className);

  return (
    <div className={classes.join(' ')}>
      { children }
    </div>
  );
}

export default Col;