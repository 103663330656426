
import CashoutFormModal from 'pages/Client/Cashout/components/CashoutFormModal';
import DepositFormModal from 'pages/Client/Deposit/components/DepositFormModal';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import CurrencyUtils from 'utils/CurrencyUtils';
import { NavigationTypes } from '../../reducers/navigationReducer';
import { ApplicationState } from '../../store';
import ContentLoader from 'react-content-loader'

import "./sidebar.scss";
import HiddenValues from './HiddenValues';
import { FormattedMessage } from 'react-intl';
import { IoPieChartOutline, IoLayersOutline, IoDownloadOutline, IoTrendingUpOutline, IoPersonCircleOutline, IoServerOutline, IoHelpCircleOutline, IoLogOutOutline, IoDocumentTextOutline, IoMegaphoneOutline, IoReorderFourOutline } from 'react-icons/io5';
import CurrencySwitcher from './CurrencySwitcher';
import { useState } from 'react';
import { APP_LEGAL_NAME_F } from 'types/config';
import FundSwitcher from './FundSwitcher';
import { useBeta } from 'reducers/userReducer';

/**
 *  Menu
 */
export type MenuItemProps = {
  title: any,
  linkTo?: string,
  icon?: any
  count?: number,
  name?: string,
  brOnly?: boolean
}

const MenuItem: React.FC<MenuItemProps> = ({ title, linkTo, icon, count, brOnly, name = '' }) => {

  const location = useLocation();
  const [active, setActive] = useState(false);
  
  useEffect(() => {
    setActive(linkTo ? location.pathname === linkTo && location.pathname.indexOf(linkTo) !== -1  : false);
  }, [linkTo, location.pathname])

  const blank = linkTo?.indexOf('http') !== -1;
  const link = linkTo ? linkTo : '/';

  if (brOnly && process.env.REACT_APP_OFFICE_ID !== '1') {
    return null;
  }


  return (
    <li>
      <Link to={blank ? { pathname: link } : link} target={blank ? '_blank' : ''} className={"item " + (active ? 'active' : '')} >
        {
          icon
            ? <div className="icon-box bg-primary">
              {icon}
            </div>
            : null
        }
        <div className="in">
          {title}
          {
            name === 'complaint'
              ? <span className="float-right"> 
                <img src="/rsm-logo.png" alt="rsm logo" style={{ height: 15, marginRight: -20 }} />
              </span>
              : null
          }
          {
            count
              ? <span className="badge badge-primary">{count}</span>
              : null
          }
        </div>
      </Link>
    </li>
  );
}

export const MENU_ITEMS = [
  {
    title: <FormattedMessage id="dashboard.title" />,
    icon: <IoPieChartOutline />,
    linkTo: '/'
  },
  {
    title: <FormattedMessage id="global.plural.deposit" />,
    icon: <IoLayersOutline />,
    linkTo: '/deposit'
  },
  {
    title: <FormattedMessage id="global.plural.cashout" />,
    linkTo: '/cashout',
    icon: <IoDownloadOutline />
  },
  {
    title: <FormattedMessage id="global.plural.income" />,
    linkTo: '/investments',
    icon: <IoTrendingUpOutline />
  },
  {
    title: <FormattedMessage id="statement.title" />,
    linkTo: '/statement',
    icon: <IoReorderFourOutline />
  }
];

const APP_MENU_ITEMS = [
  {
    title: <FormattedMessage id="global.account" />,
    icon: <IoPersonCircleOutline color="white" />,
    linkTo: '/account'
  },
  {
    title: <FormattedMessage id="ir.title" values={{ v: '' }} />,
    icon: <IoDocumentTextOutline color="white" />,
    linkTo: '/ir'
  },
  {
    title: <FormattedMessage id="sidebar.ethic" />,
    icon: <IoMegaphoneOutline color="white" />,
    linkTo: '/ethics',
    usOnly: true
  },
  {
    title: <FormattedMessage id="sidebar.ethic" />,
    icon: <IoMegaphoneOutline color="white" />,
    linkTo: '/ethics',
    usOnly: true
  },
  {
    title: <FormattedMessage id="bank.title" />,
    icon: <IoServerOutline color="white" />,
    linkTo: '/banks'
  },
  {
    title: <FormattedMessage id="global.support"/>,
    linkTo: '/support',
    icon: <IoHelpCircleOutline color="white" />
  },
  {
    title: <FormattedMessage id="global.complaint"/>,
    linkTo: 'https://denuncia.sbarainicapital.com.br',
    icon: <IoMegaphoneOutline color="white" />,
    brOnly: true,
    name: 'complaint'
  },
  {
    title: <FormattedMessage id="global.logout" />,
    icon: <IoLogOutOutline />,
    linkTo: '/logout'
  }
];

/**
 *  SIDEBAR
 */
const Sidebar = () => {

  const opened            = useSelector((state: ApplicationState) => state.navigation.sidebar);
  const authUser          = useSelector((state: ApplicationState) => state.user.authUser);
  const depositModal      = useSelector((state: ApplicationState) => state.client.depositFormOpen);
  const cashoutModal      = useSelector((state: ApplicationState) => state.client.cashoutFormOpen);
  const currencyType      = useSelector((state: ApplicationState) => state.user.currencyType);
  const showValues        = useSelector((state: ApplicationState) => state.navigation.showValues);

  const isBetaTester = useBeta();
  const location     = useLocation();
  const dispatch     = useDispatch();

  const toggleSidebar = () => dispatch({ type: NavigationTypes.TOGGLE_SIDEBAR });

  useEffect(() => {
    const bodyElement: any = document.body;

    if ( opened ) {
      bodyElement.classList.add('body-on-modal');
    } else {
      bodyElement.classList.remove('body-on-modal');
    }
  }, [opened]);

  const hiddenSidebar = [
    '/auth'
  ].filter( route => location.pathname.indexOf(route) !== -1 ).length > 0;

  if ( hiddenSidebar ) {
    return null;
  }

  return (
    <>
      <div className={"sidebar-overlay " + (opened ? 'open' : '')} onClick={toggleSidebar}></div>
      <div className={"sidebar smart-scroll " + (opened ? 'open' : '')}>
        <div className="profileBox pt-2 pb-2">
          <div className="in">
            <strong>{authUser?.name}</strong>
            <div className="text-muted">{authUser?.email}</div>
          </div>
          <a href="/" className="btn btn-link btn-icon sidebar-close">
          </a>
        </div>
        <div className="sidebar-balance">
          <div className="listview-title">
            <FormattedMessage
              id="global.balance"
              defaultMessage="Balance" />
          </div>
          <div className="in">
            {
                authUser?.useramount && showValues
                ? <h1 className="amount">
                    { CurrencyUtils.format(authUser?.useramount[0][currencyType.toLowerCase()], currencyType) }
                  </h1>
                : <ContentLoader width={160} height={28} animate={!authUser?.useramount} viewBox="0 0 160 28" speed={0.8} backgroundColor={'rgba(0,0,0,0.4)'} foregroundColor={'rgba(0,0,0,0.55)'}>                    <rect x="0" y="0" rx="4" ry="4" width="160" height="28" />
                  </ContentLoader>
            }
            <HiddenValues />
          </div>
        </div>
        <div className="action-group pt-2 pb-2">
          <CurrencySwitcher name="currency-sidebar" invertColors />
        </div>

        {
          isBetaTester && process.env.REACT_APP_OFFICE_ID === '1'
            ? <FundSwitcher />
            : null
        }
        
        <div className="listview-title mt-1">Menu</div>
        <ul className="listview flush transparent no-line image-listview">
          {
            MENU_ITEMS.map((item, index) => <MenuItem key={'su' + index} {...item} />)
          }
        </ul>
        <div className="listview-title mt-1">
          <FormattedMessage id="sidebar.others" defaultMessage="Others" />
        </div>
        <ul className="listview flush transparent no-line image-listview">
          { 
            APP_MENU_ITEMS.map((item, index) => {
              if (item.usOnly && process.env.REACT_APP_OFFICE_ID === '1') {
                return null;
              }
              
              return (<MenuItem key={'sb' + index} {...item} />);
            })
          }
        </ul>
        <div className="copyright text-center px-2 d-flex flex-column">
          <strong>{ APP_LEGAL_NAME_F }</strong>
          <small>Copyright © 2019-{ (new Date()).getFullYear() } </small>

          {
            process.env.REACT_APP_OFFICE_ID === '2'
              ? null
              : <small>
                  <Link to="policy">
                    <FormattedMessage id="legal.privacy_policy" />  
                  </Link>
                  <span> - </span>  
                  <Link to="terms">
                    <FormattedMessage id="legal.terms_of_use" />
                  </Link>
                </small>
          }
          
        </div>
      </div>

      { depositModal ? <DepositFormModal /> : null }
      { cashoutModal ? <CashoutFormModal /> : null }
    </>
  );
}

export default Sidebar;
