import BankRequests from "api/BankRequests";
import CopyContent from "common/components/CopyContent";
import Alert from "common/components/ui/Alert";
import { Col, Row } from "common/components/ui/Layout";
import React, { useEffect, useState } from "react";
import { Deposit } from "types/Deposit";
import BankItem from "./BankItem";

type Props = {
  deposit: Deposit
}

const DepositInformation: React.FC<Props> = ({ deposit }) => {
  const [banks, setBanks] = useState<any>([]);
  const [fetch, setFetch] = useState<any>(true);
  const [country, setCountry] = useState<'BR'|'US'|'WALLET'|null>(null);

  useEffect(() => {
    if (deposit.type === 'USD') {
      setCountry('US');
    }

    if (deposit.type === 'USDT') {
      setCountry('WALLET');
    }

    if (deposit.type === 'BRL') {
      setCountry('BR')
    }
  }, [deposit]);

  useEffect(() => {
    if (country) {
      BankRequests.mk(country)
        .then( response => {
          setBanks(response.data);
          setFetch(false);
        })
        .catch( error => setFetch(null))
    }
  }, [country])

  return (
    <div className="depositInformation">
      <Row>
        <Col sm={12} md={12} lg={12}>
          {
            deposit.type !== 'BRL'
              ? null
              : <Alert className="mt-3" simple 
                  translate
                  message="bank.note" 
                  color="secondary" 
                  outline />
          }
          { 
            country === 'BR'
              ? <ul className="listview flush transparent simple-listview no-space my-3">
                  <li>
                    <strong>Favorecido</strong>
                    <span>Sbaraini Administradora de Capitais Ltda.</span>
                  </li>
                  <li>
                    <strong>CNPJ</strong>
                    <CopyContent text="32.020.860/0001-83" />
                  </li>
                </ul>
              : null
          }
          
          {
            Array.isArray(banks) && !fetch
             ? banks.map( (bank, index) => <BankItem key={'bo' + index} { ...bank } option={index + 1} />)
             : null
          }
          <Alert message="Entre em contato conosco para outros métodos de pagamento." color="danger" />
        </Col>
      </Row>
    </div>
  )
}

export default DepositInformation;