class CurrencyUtils {
  static toBRL(value: number) {
    return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
  }

  static toUSD(value: number) {
    return Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD'}).format(value);
  }

  static toUSDT(value: number) {
    return Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD', })
      .format(value)
      .replace('$', 'USDT ');
  }

  static toDRC(value: number) {
    return Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD', })
      .format(value)
      .replace('$', 'DRC ');
  }

  static getCurrencySymbol(currency: string) {
    if ( currency === 'BTC' ) {
      return 'BTC';
    } else if ( currency === 'BRL' ) {
      return 'R$';
    } else if ( currency === 'USDT' ) {
      return 'USDT';
    } else {
      return 'U$';
    }
  }

  static format(value: number, type: 'BTC'|'USD'|'BRL'|'DRC'|string|undefined) {
    if ( type === 'BRL' ) 
      return CurrencyUtils.toBRL(value);

    if ( type === 'USD' ) 
      return CurrencyUtils.toUSD(value);

    if ( type === 'USDT' ) 
      return CurrencyUtils.toUSDT(value);

    if ( type === 'DRC' ) 
      return CurrencyUtils.toDRC(value);

    if ( type === 'BTC' ) {
      if ( typeof value !== 'number' ) {
        value = parseFloat(value);
      }

      return 'BTC ' + value.toFixed(8);
    }

    return value;
  }

  static availableCurrency(authUser: any){
    const currencyOptions: string[] = [];
    
    // { value: 'BRL', description: 'BRL' }

    if (process.env.REACT_APP_OFFICE_ID === '1') {
      if (currencyOptions.indexOf('BRL') === -1 && authUser?.aliquot_brl > 0) {
        currencyOptions.push('BRL');  
      }

      if (currencyOptions.indexOf('BRL') === -1 && authUser?.useramount[0] && authUser?.useramount[0].brl > 0 ) {
        currencyOptions.push('BRL');  
      }
    }

    ['BTC', 'USD', 'USDT', 'DRC'].forEach(currency => {
      if (process.env.REACT_APP_OFFICE_ID === '2' && currency === 'BTC') return;
      if (process.env.REACT_APP_OFFICE_ID === '2' && currency === 'BRL') return;
      // if (process.env.REACT_APP_OFFICE_ID === '2' && currency === 'USDT') return;
      
      if (!authUser && currency === 'DRC') return;
      if (currency === 'DRC' && (authUser && [1790, 5502].indexOf(authUser.id) === -1)) return;

      const aliquotKey = `aliquot_${currency.toLowerCase()}`;
      if (currencyOptions.indexOf(currency) === -1 && authUser[aliquotKey] && authUser[aliquotKey] > 0) {
        currencyOptions.push(currency);  
      }
  
      if (currencyOptions.indexOf(currency) === -1 && authUser?.useramount[0] && authUser?.useramount[0][currency.toLowerCase()] > 0) {
        currencyOptions.push(currency);  
      }
    });

    return currencyOptions.map(cur => ({ value: cur, description: cur }));
  }

  static usdToReal(value: string) {
    return value.replace(/,/g, '#').replace(/\./g, ',').replace(/#/g, '.');
  }

  static toNumber(value: string, type: string = 'BRL') {
    if ( type === 'USD' || type === 'USDT' || type === 'DRC') {
      return parseFloat(value.replace(/,/g, ''));
    } else if ( type === 'BRL' ) {
      return parseFloat(value.replace(/\./g, '').replace(/,/g, '.'));
    } else {
      return parseFloat(value);
    }
  }

  static getFirstCurrency(user: any) {
    const office_id = process.env.REACT_APP_OFFICE_ID;

    if ( user.aliquot_brl > 0 && office_id !== '2') {
      return 'BRL';
    }
    if ( user.aliquot_usd > 0 ) {
      return 'USD';
    }

    if ( user.aliquot_usdt > 0 ) {
      return 'USDT';
    }

    if ( user.aliquot_btc > 0 && office_id !== '2') {
      return 'BTC';
    }

    return 'BRL';
  }
}
export default CurrencyUtils;

export type CurrenciesTypes = 'BTC'|'USD'|'BRL'|'DRC';