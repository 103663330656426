import RectLoader from 'common/components/RectPlaceholder';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { CurrencyUtils } from 'utils';

type Props = {
  currencyType: string,
  value: number
}

const FundAmount: React.FC<Props> = ({ currencyType, value }) => {

  const showValues = useSelector((state: ApplicationState) => state.navigation.showValues);

  return showValues
    ? <h3 className="text-success m-0">{ CurrencyUtils.format(value, currencyType) }</h3> 
    : <RectLoader animate={!value} width={120} height={21} />
}

export default FundAmount;