import { api, getRequestOptions } from ".";

export default class SupportRequests {
  static details() {
    return api.get('/support/details', {...getRequestOptions() });
  }

  static term() {
    return api.get('/terms', getRequestOptions());
  }

  static accept() {
    return api.post('/terms', null, getRequestOptions());
  }
}