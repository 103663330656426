import { setupAxiosInterceptors } from "api";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

type Props = {
  children: any;
}

const AxiosRegistry: React.FC<Props> = ({ children }) => {
  
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setupAxiosInterceptors(history, dispatch);

    // eslint-disable-next-line
  }, [history]);

  return (
    <>
      { children }
    </>
  )
}

export default AxiosRegistry;