import React from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import { IoCopyOutline } from "react-icons/io5";
import "./components.scss";
import useTranslation from "hooks/useTranslation";
import { Button } from "./ui/Form";

type Props = {
  text: string,
  copyOnlyNumbers?: boolean,
  className?: string,
  toastMessage?: string,
  before?: boolean,
  wrap?: boolean,
  customText?: false|string,
  asButton?: boolean
}

const CopyContent: React.FC<Props> = ({ asButton = false, customText = false, text, copyOnlyNumbers, className = '', before = false, toastMessage, wrap = false }) => {

  const ref: any = useRef(null);
  const dispatch = useDispatch();
  const i = useTranslation();

  if ( !toastMessage ) {
    toastMessage = i._('global.copied');
  }

  const copyToClipboard = () => {
    dispatch({ type: NavigationTypes.SHOW_TOAST, payload: toastMessage })
    const el = document.createElement('textarea');

    if ( copyOnlyNumbers ) {
      el.value = text.replace(/\D/g, '');
    } else {
      el.value = text;
    }

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  return (
    <span className={"CopyContent " + className + " " + (before ? 'before' : '')} style={{ whiteSpace: wrap ? 'pre-line' : 'unset' }}>
      <textarea defaultValue={text} style={{ display: 'none' }} ref={ref}></textarea>
      { before && !asButton ? <IoCopyOutline size={20} onClick={copyToClipboard} /> : null }
      { !asButton ? (customText ? customText : text) : null }
      { !before && !asButton ? <IoCopyOutline size={20} onClick={copyToClipboard} /> : null }
      { asButton && <Button title={customText ? customText : text} onClick={copyToClipboard} color="primary" type="button" icon={<IoCopyOutline size={20} />} /> }
    </span>
  );
}

export default CopyContent;