import { Bank, Cashout, Deposit, Document } from "types/Deposit";
import { Reducer } from "redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

export interface ClientState {
  depositFormOpen: boolean,
  cashoutFormOpen: boolean,
  expiratedDeposits: any|null,
  deposits: Deposit[],
  banks: Bank[],
  wallets: any[],
  cashouts: any[],
  deposit: Deposit,
  cashout: Cashout,
  documents: Document[],
  profileProgress: number,
  termsModal: boolean,
}

export enum ClientTypes {
  TOGGLE_DEPOSIT_FORM   = '@@client/TOGGLE_DEPOSIT_FORM',
  UPDATE_DEPOSIT_FORM   = '@@client/UPDATE_DEPOSIT_FORM',
  TOGGLE_CASHOUT_FORM   = '@@client/TOGGLE_CASHOUT_FORM',
  UPDATE_CASHOUT_FORM   = '@@client/UPDATE_CASHOUT_FORM',
  UPDATE_DEPOSIT_LIST   = '@@client/UPDATE_DEPOSIT_LIST',
  UPDATE_CASHOUT_LIST   = '@@client/UPDATE_CASHOUT_LIST',
  UPDATE_EXPIRATED_LIST = '@@client/UPDATE_EXPIRATED_LIST',
  TOGGLE_RENEW_FORM     = '@@client/TOGGLE_RENEW_FORM',
  ADD_DEPOSIT           = '@@client/ADD_DEPOSIT',
  ADD_CASHOUT           = '@@client/ADD_CASHOUT',
  UPDATE_DEPOSIT        = '@@client/UPDATE_DEPOSIT',
  UPDATE_CASHOUT        = '@@client/UPDATE_CASHOUT',
  UPDATE_DOCUMENT_LIST  = '@@client/UPDATE_DOCUMENT_LIST',
  ADD_DOCUMENT          = '@@client/ADD_DOCUMENT',
  DELETE_DOCUMENT       = '@@client/DELETE_DOCUMENT',
  UPDATE_BANK_LIST      = '@@client/UPDATE_BANK_LIST',
  ADD_BANK              = '@@client/ADD_BANK',
  DELETE_BANK           = '@@client/DELETE_BANK',
  UPDATE_WALLET_LIST    = '@@client/UPDATE_WALLET_LIST',
  ADD_WALLET            = '@@client/ADD_WALLET',
  DELETE_WALLET         = '@@client/DELETE_WALLET',
  ADD_PROFILE_PROGRESS  = '@@client/ADD_PROFILE_PROGRESS',
  TOGGLE_TERMS_MODAL    = '@@client/TOGGLE_TERMS_MODAL',
  RESET                 = '@@client/RESET'
}

const INITITAL_STATE: ClientState = {
  depositFormOpen: false,
  cashoutFormOpen: false,
  expiratedDeposits: null,
  deposits: [],
  banks: [],
  wallets: [],
  cashouts: [],
  deposit: {
    receipt: []
  },
  cashout: {
    receipts: []
  },
  documents: [],
  profileProgress: 0,
  termsModal: false
}

export const clientReducer: Reducer<ClientState> = (state = INITITAL_STATE, action) => {
  switch ( action.type ) {
    case ClientTypes.TOGGLE_DEPOSIT_FORM:
      return {
        ...state,
        depositFormOpen: !state.depositFormOpen
      };
    case ClientTypes.UPDATE_DEPOSIT_LIST:
      return {
        ...state,
        deposits: action.payload
      };
    case ClientTypes.UPDATE_CASHOUT_LIST:
      return {
        ...state,
        cashouts: action.payload
      };
    case ClientTypes.UPDATE_DEPOSIT:
      return {
        ...state,
        deposit: action.payload
      };
    case ClientTypes.UPDATE_CASHOUT:
        return {
          ...state,
          cashout: action.payload
        };
    case ClientTypes.ADD_DEPOSIT:
      return {
        ...state,
        deposits: [action.payload, ...state.deposits]
      };
    case ClientTypes.UPDATE_EXPIRATED_LIST:
      return {
        ...state,
        expiratedDeposits: action.payload
      };
    case ClientTypes.ADD_CASHOUT:
      return {
        ...state,
        cashouts: [action.payload, ...state.cashouts]
      };
    case ClientTypes.TOGGLE_CASHOUT_FORM:
      return {
        ...state,
        cashoutFormOpen: !state.cashoutFormOpen
      }
    case ClientTypes.UPDATE_CASHOUT_FORM:
      return {
        ...state,
        cashoutFormOpen: action.payload
      };
    case ClientTypes.TOGGLE_RENEW_FORM:
      return {
        ...state,
        renewFormOpen: action.payload
      }
    case ClientTypes.UPDATE_DEPOSIT_FORM:
      return {
        ...state,
        depositFormOpen: action.payload
      };
    case ClientTypes.UPDATE_DOCUMENT_LIST:
      return {
        ...state,
        documents: action.payload
      };
    case ClientTypes.ADD_DOCUMENT: 
      return {
        ...state,
        documents: [action.payload, ...state.documents]
      };
    case ClientTypes.DELETE_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents.filter(document => document.id !== action.payload)]
      };
    case ClientTypes.UPDATE_BANK_LIST:
      return {
        ...state,
        banks: action.payload
      };
    case ClientTypes.ADD_BANK:
      return {
        ...state,
        banks: [action.payload, ...state.banks]
      };
    case ClientTypes.DELETE_BANK:
      return {
        ...state,
        banks: [ ...state.banks.filter( bank => bank.id !== action.payload )]
      };
    case ClientTypes.UPDATE_WALLET_LIST:
      return {
        ...state,
        wallets: action.payload
      };
    case ClientTypes.ADD_WALLET:
      return {
        ...state, 
        wallets: [action.payload, ...state.wallets]
      };
    case ClientTypes.ADD_PROFILE_PROGRESS:
      return {
        ...state,
        profileProgress: state.profileProgress + 1
      };
    case ClientTypes.DELETE_WALLET:
      return {
        ...state, 
        wallets: [ ...state.wallets.filter( wallet => wallet.id !== action.payload )]
      };
    case ClientTypes.TOGGLE_TERMS_MODAL: 
      return {
        ...state,
        termsModal: typeof action.payload === 'boolean' ? action.payload : false
      };
    case ClientTypes.RESET:
      return INITITAL_STATE;
    default:
      return state;
  }
}

export const useCurrentCashout = () => useSelector((state: ApplicationState) => state.client.cashout);