import React from "react";
import { IoChevronBackOutline, IoArrowBackOutline } from "react-icons/io5";
import { useHistory, useLocation } from "react-router";
import { useCurrentFund } from "reducers/navigationReducer";
import { useBeta } from "reducers/userReducer";
import { Colors } from "types/config";
import FundLogo from "./logos/FundLogo";

export type PageHeaderProps = {
  title?: string;
  rightHeader?: any;
  goBackLink?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, rightHeader, goBackLink }) => {
  const history = useHistory();
  const fund = useCurrentFund();
  const beta = useBeta();
  const location = useLocation();


  return (
    <div className="header page">
      <div className="left">
        {
          goBackLink
            ? <>
                <div onClick={() => goBackLink ? history.replace(goBackLink) : history.goBack()} className="headerButton d-md-none goBack">
                  <IoChevronBackOutline size={20} />
                </div>
                <div onClick={() => goBackLink ? history.replace(goBackLink) : history.goBack()} className="headerButton d-none d-md-flex goBack header-desktop">
                  <IoArrowBackOutline size={20} />
                </div>
              </>
            : null
        }
      </div>
      <div className="pageTitle">
        {title}
      </div>
      {
        rightHeader
          ? rightHeader
          : null
      }
      {
        fund && beta && location.pathname !== '/overview' && process.env.REACT_APP_OFFICE_ID === '1'
          ? <div className="desktop-fund-indicator d-none d-md-block">
              <FundLogo id={fund.id} color={Colors.PRIMARY} size={20} />
              { fund.name }
            </div>
          : null
      }

    </div>
  );
}

export default PageHeader;