import { Button } from "common/components/ui/Form";
import React from "react";
import { IoAddCircleOutline, IoCheckmarkCircle } from "react-icons/io5"

type Props = {
    onReset: () => void
}
const LatamSuccess: React.FC<Props> = ({ onReset }) => {
    return <div className="text-center mt-3">
        <IoCheckmarkCircle size={30} className="text-success" />
        <h5 className="text-center mt-3">
            Payment Completed
        </h5>
        <p className="mt-2">
            We identify the payment and soon the deposit will be registered in your account.
        </p>
        <Button onClick={onReset} icon={<IoAddCircleOutline size={18} />} color="primary" title="New deposit" type="button" className="mt-4" />
    </div>
}

export default LatamSuccess;