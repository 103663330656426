import React from "react";
import ClientRequest from "api/ClientRequest";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, UserTypes } from "reducers/userReducer";
import { ApplicationState } from "store";
import MultiButton from "./ui/Form/MultiButton";
import { useState } from "react";
import CurrencyUtils from "utils/CurrencyUtils";

type Props = {
  name?: string;
  invertColors?: boolean
}

const CurrencySwitcher: React.FC<Props> = ({ name, invertColors }) => {
  
  const authUser                 = useAuth();
  const dispatch                 = useDispatch();
  const currencyType             = useSelector((state: ApplicationState) => state.user.currencyType);
  const [disabled, setDisabled ] = useState(false);

  const getCurrencyOptions = (authUser: any): any => {

    if(authUser) {

      const currencies = CurrencyUtils.availableCurrency(authUser);
      
      // if returns only one currencie option, switch to default
      if (currencies.length === 1) {
        dispatch({
          type: UserTypes.UPDATE_CURRENCY_TYPE,
          payload: currencies[0].value
        });
      }
      
      return currencies;
    }

  }

  const onChangeCurrencyType = (e: any) => {
    setDisabled(true);
    ClientRequest.totals(authUser.id)
      .then(response => dispatch({ type: UserTypes.UPDATE_TOTALS, payload: response.data }))
      .catch(error => console.log('Error to fetch client totals'))
      .finally(() => {
        dispatch({
          type: UserTypes.UPDATE_CURRENCY_TYPE,
          payload: e.target.value
        });
        setDisabled(false);
      });
  }

  return (
    <MultiButton 
      block 
      onChange={onChangeCurrencyType} 
      className={invertColors ? 'invert' : ''}
      value={currencyType} 
      name={name || "currency"}
      disabled={disabled}
      translate={false}
      options={getCurrencyOptions(authUser)} />
  );
}

export default CurrencySwitcher;
