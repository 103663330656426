import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuItemProps, MENU_ITEMS } from "./Sidebar";

import "./components.scss";

const BottomItemMenu: React.FC<MenuItemProps> = ({ title, linkTo, icon, count }) => {
  const [active, setActive] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    if ( title === 'Dashboard' && location.pathname === '/' ) setActive(true); 
    else setActive(linkTo && linkTo !== '/' ? location.pathname.indexOf(linkTo) !== -1  : false);
  }, [linkTo, location.pathname, title])

  return (
    <Link to={linkTo || '/'} className={'item ' + (active ? 'active' : '')}>
      <div className="col">
        { icon }
        <strong>{ title }</strong>
      </div>
    </Link>
  )
}

const BottomMenu = () => {
  return (
    <>
      <div className="appBottomMenu d-md-none">
        {
          MENU_ITEMS.slice(0, 3).map((item, key) => <BottomItemMenu {...item} key={'bm-' + key} />)
        }
      </div>
      <div className="clear-bottom-menu">&nbsp;</div>
    </>
  )
}

export default BottomMenu;