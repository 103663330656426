import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { IoMenuOutline, IoPersonCircleOutline, IoHelpOutline, IoExitOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'reducers/userReducer';
import { NavigationTypes } from '../../reducers/navigationReducer';

import "./header.scss";
import ArbLogo from './logos/ArbLogo';
import { SbarainiLogo } from './logos';
import SwitchLang from './SwitchLang';
import Dropdown, { DropdownDivider, DropdownItem } from './ui/Dropdown';


type DropdownOptions = {
  label: string,
  icon: React.ReactNode,
  redirect: string,
}

type Props = {
  extraHeader?: boolean;
  profileOptions?: DropdownOptions[]|false
  sidebar?: boolean,
  noAuth?: boolean,
  fundSwitcher?: boolean,
}

const DEFAULT_PROFILE_OPTIONS: DropdownOptions[] = [
  {
    label: 'global.account',
    icon: <IoPersonCircleOutline />,
    redirect: '/account'
  },
  {
    label: 'global.support',
    icon: <IoHelpOutline color="#333333" />,
    redirect: '/support'
  }
];

const Header: React.FC<Props> = ({ 
  extraHeader, 
  profileOptions = DEFAULT_PROFILE_OPTIONS, 
  sidebar = true,
  fundSwitcher = true,
  noAuth = false 
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useAuth();
  const i = useTranslation();
  const toggleSidebar = () => dispatch({ type: NavigationTypes.TOGGLE_SIDEBAR });

  return (
    <>
      <div className={"header bg-primary text-light app-header"}>
        {
          sidebar
            ? <div className="left d-md-none d-lg-none">
                <div onClick={toggleSidebar} className="headerButton">
                  <IoMenuOutline size={20} />
                </div>
              </div>
            : null
        }
        <div className="pageTitle">
          {
            process.env.REACT_APP_OFFICE_ID === '2'
              ? <ArbLogo size={33} color={false} />
              : <SbarainiLogo color='white' size={32} />
          }
        </div>
        <div className="right">
          {
            profileOptions
              ? <Dropdown icon={<IoPersonCircleOutline size={20} />} iconMobile title={authUser?.name.split(' ')[0]}>
                  {
                    profileOptions.map((option, index) => 
                      <DropdownItem 
                        title={i._(option.label)} 
                        icon={option.icon}
                        key={"he-" + index}
                        value={'o' + index} 
                        onClick={v => history.push(option.redirect)} />
                    )
                  }
                  <DropdownDivider />
                  <DropdownItem title={i._('global.logout')} icon={<IoExitOutline color="#333333" />} value="o3" onClick={v => history.push('/logout')} />
                </Dropdown>
              : null
          }
          {
					  process.env.REACT_APP_OFFICE_ID === '1'
              ? <SwitchLang className="d-none d-md-block" />
              : null
          }
          
        </div>
      </div>
      {
        extraHeader 
          ? <div className="extra-header"></div>
          : null
      }
    </>
  )
}

export default Header;